/** @format */

import React from 'react';
import { TooltipProps } from '@mui/material/Tooltip';

import { LightTooltip } from './Styled';

type CustomLightTooltipProps = TooltipProps;

const CustomLightTooltip: React.FC<CustomLightTooltipProps> = (props) => {
  return <LightTooltip {...props} />;
};

export default CustomLightTooltip;
