/** @format */

import { DateTime } from 'luxon';
import _ from 'underscore';

export const dateFormater = (date: string | number | Date): Date => {
  const formatedDate = DateTime.fromJSDate(new Date(date)).toFormat('yyyy-MM-dd');
  return new Date(`${formatedDate} 00:00:00`);
};

export const camelize = (str: string) => {
  str = str.trim();
  let arr: string | any[] = [];

  if (str.includes('-')) arr = str.split('-');
  else if (str.includes('_')) arr = str.split('_');
  else if (str.includes(' ')) arr = str.split(' ');
  else arr = [str];

  let string = '';
  for (let i = 0; i < arr.length; i++) {
    if (i === 0) {
      string += arr[i].toLowerCase();
      // string += arr[i].slice(1, arr[i].length);
    } else {
      string += arr[i][0].toUpperCase();
      string += arr[i].slice(1, arr[i].length).toLowerCase();
    }
  }

  return string;
};

export const calculatePercentageDiff = (x1: number, x2: number) => {
  // x1 => initial value
  // x2 => final value
  const result = ((x2 - x1) / x1) * 100;
  return result.toFixed(0);
};

export const getFormattedObjectForVitalsUnitConverter = (type: string, name: string, value: number | string, unit: string, sourceType: string) => {
  return {
    type,
    measurements: [
      {
        name,
        value,
        unit,
      },
    ],
    sourceType,
  };
};

export const getUniqueDateData = (startDate: string, endDate: string) => {
  // Filtering Unique Date Data For CORE-AXIS--X
  const uniqueDateData: any = [];
  const { days } = DateTime.fromISO(startDate).diff(DateTime.fromISO(endDate), ['days']).toObject();
  for (let i = 0; i <= Math.abs(days!); i++) {
    const date = DateTime.fromJSDate(new Date(startDate)).plus({ days: i }).toISO() as string;
    uniqueDateData.push({ date: DateTime.fromJSDate(new Date(date)), value: null });
  }
  // Filtering Unique Date Data For CORE-AXIS--X

  return uniqueDateData;
};

export const calculateMedians = (data: [{ [key: string]: any; date: Date; value: number }]) => {
  // Group data by date
  const groupedByDate = _.groupBy(data, 'date');

  const dayWiseMedians = _.map(groupedByDate, (values, date) => {
    // Extract values for the date
    const dayValues: number[] = _.pluck(values, 'value');

    // Sort the values
    const sortedValues: number[] = _.sortBy(dayValues, (num: number | string) => {
      return parseFloat(String(num));
    });

    // Calculate the median for the day
    const length = sortedValues.length;
    const middle = Math.floor(length / 2);
    let median: number;
    // If the length is even, calculate the average of the two middle values
    if (length % 2 === 0) {
      const leftMiddleValue: number = parseFloat(String(sortedValues[middle - 1]));
      const rightMiddleValue: number = parseFloat(String(sortedValues[middle]));

      median = (leftMiddleValue + rightMiddleValue) / 2;
    } else {
      // If the length is odd, return the middle value
      median = sortedValues[middle];
    }

    // Return an object with date and median
    return { date: dateFormater(date), value: median };
  });

  // Calculate median for each day and sort by date
  const mediansPerDay = _.sortBy(dayWiseMedians, 'date');

  return mediansPerDay;
};

export const getMinMedianMaxDayWise = (data: [{ [key: string]: any; date: Date; value: number }]) => {
  // Group data by date
  const groupedByDate = _.groupBy(data, 'date');

  const dayWiseMinAndMax = _.map(groupedByDate, (values, date) => {
    // Extract values for the date
    const dayValues: number[] = _.pluck(values, 'value');

    // Sort the values
    const sortedValues: number[] = _.sortBy(dayValues, (num: number | string) => {
      return parseFloat(String(num));
    });

    // Calculate the median for the day
    const length = sortedValues.length;
    const middle = Math.floor(length / 2);

    let medianValue: number;
    // If the length is even, calculate the average of the two middle values
    if (length % 2 === 0) {
      const leftMiddleValue: number = parseFloat(String(sortedValues[middle - 1]));
      const rightMiddleValue: number = parseFloat(String(sortedValues[middle]));

      medianValue = (leftMiddleValue + rightMiddleValue) / 2;
    } else {
      // If the length is odd, return the middle value
      medianValue = sortedValues[middle];
    }

    const minValue = sortedValues[0];
    const maxValue = sortedValues[sortedValues.length - 1];

    // Return an object with date and median
    return [
      { timestamp: date, date: dateFormater(date), value: minValue },
      { timestamp: date, date: dateFormater(date), value: medianValue },
      { timestamp: date, date: dateFormater(date), value: maxValue },
    ];
  });

  const flattenedDayWiseMinAndMax = _.flatten(dayWiseMinAndMax);

  return flattenedDayWiseMinAndMax;
};

export const getMaxDayWise = (data: [{ [key: string]: any; date: Date; value: number }]) => {
  // Group data by date
  const groupedByDate = _.groupBy(data, 'date');

  const dayWiseMax = _.map(groupedByDate, (values, date) => {
    // Extract values for the date
    const dayValues: number[] = _.pluck(values, 'value');

    // Sort the values
    const sortedValues: number[] = _.sortBy(dayValues, (num: number | string) => {
      return parseFloat(String(num));
    });

    // const minValue = sortedValues[0];
    const maxValue = sortedValues[sortedValues.length - 1];

    // Return an object with date and median
    return { date: dateFormater(date), value: maxValue };
  });

  const sortedMaxValueDateWise = _.sortBy(dayWiseMax, 'date');

  return sortedMaxValueDateWise;
};

export const filterDateWise = (data: { [key: string]: any; date: string }[], startDate: string, endDate: string) => {
  const sDate = DateTime.fromISO(startDate).toMillis();
  const eDate = DateTime.fromISO(endDate).toMillis();

  const filteredData = data?.filter((item) => {
    const itemDate = DateTime.fromISO(DateTime.fromJSDate(new Date(item.date)).toFormat('yyyy-MM-dd')).toMillis();

    return itemDate >= sDate && itemDate <= eDate;
  });

  return filteredData;
};
