/** @format */

import { DateTime } from 'luxon';
import { renderToString } from 'react-dom/server';
import { vitalsUnitConverters } from 'vitals-convertor';
import FlagTwoToneIcon from '@mui/icons-material/FlagTwoTone';
import { ChartDataType } from 'tricog-d3/dist/types/LineChart.types';
import {
  BarChartPluginClass,
  ChartClass,
  ChartCoreClass,
  DotsAndTooltipsPluginClass,
  GridPluginClass,
  LinePluginClass,
  SVGEmojiesAndTooltipPluginClass,
  SVGIconAndTooltipPluginClass,
} from 'tricog-d3';

import { PatientSymptomsState } from '../../../../reducers/patient-symptoms-reducer/PatientSymptomsSlice';

import CustomTable from '../../custom-table/CustomTable';
import { getMedicationAcceptanceTextFormat, getSymptomsTextFormat } from '../../../../utils/timeline-data';

import { customConvertFloatToInt } from '../../../../utils/patient-vitals-utils';
import { calculateMedians, calculatePercentageDiff, getFormattedObjectForVitalsUnitConverter, getMaxDayWise } from './utils';

import { LAB_REPORTS_MAP_LABEL } from '../../../../constants/VitalsMapping';
import { USER_VITALS_UNIT_MAPPING } from '../../../../constants/PatientVitals';
import { CHART_NAME_DEVICE_MAP, CHART_NAME_INIT_CURR_VALUE_MAP, CHART_NAME_UNIT_CONVERTER_MAPPING, SHOW_FLAG_AGE_VALUE_FOR, medicalEventsIconsMappedKeys, medicalEventsIcons } from './constants';

import { CustomTableColumnType } from '../../../../types/CustomTable.types';
import { xAxisCorePropsInterfacePropsType } from '../../../../types/RCACharts.types';

import { Divider, Typography } from '@mui/material';

export const createChart = (props: xAxisCorePropsInterfacePropsType, width: number, uniqueDateData: ChartDataType[], options: any) => {
  const xAxisCore = new ChartClass(`#${props.id}`, width, 5, props.margin!, props.viewBox!, uniqueDateData, options as any);

  xAxisCore.core.deleteSvgElements();
  xAxisCore.core.createSvgElement();

  xAxisCore.core.createFocusElement({ className: 'CORE-X-AXIS', marginTop: 30 });

  // Appending Common X-Axis
  xAxisCore.core.drawXAxisTimeScale();
  xAxisCore.core.drawXAxisBGRectAndCurrentDateHighlighter();

  xAxisCore.core.focusElement.attr('id', 'corexaxis');

  return xAxisCore;
};

export const createVitalsChart = (
  props: xAxisCorePropsInterfacePropsType,
  corePluginName: string,
  vitalsUnits: any,
  item: any,
  index: number,
  xAxisCore: any,
  width: number,
  height: number,
  graphsHeights: number[],
  zoomCallback: (a: any, b: any, c: any) => void,
) => {
  const focusElementHeight = index === 0 ? 36 : 36 + graphsHeights[index];

  const options: any = item.coreOptions;

  xAxisCore.registerPlugin(
    corePluginName,
    new ChartCoreClass({
      rootElement: `${props.id}`,
      width,
      height,
      margin: props.margin!,
      chartData: item.data,
      options: { ...options, zoomCallback },
    } as any),
  );

  xAxisCore.plugins[corePluginName].createFocusElement({ className: corePluginName, marginTop: focusElementHeight });
  // Adding id for focusElement
  xAxisCore.plugins[corePluginName].focusElement.attr('id', `id--${corePluginName}`);

  xAxisCore.plugins[corePluginName].xAxisLables(_renderSide(item, vitalsUnits, props), 200, height);
  xAxisCore.plugins[corePluginName].drawYAxisValue();

  xAxisCore.plugins[corePluginName].attachYAxisZoomBehavior();

  // Adding id to focusElements for making it stickey or targetting it.
  xAxisCore.plugins[corePluginName].focusElement.attr('id', corePluginName);
};

export function medicalEventToolTips (
  data: { date: any; [value: string]: any[] },
  getmasterData: PatientSymptomsState,
  medicationColumnsAndNotesColumns: { medicationColumns: CustomTableColumnType[]; medicationNotesColumns: CustomTableColumnType[] },
  vitalsColumns: CustomTableColumnType[],
  kccqColumns: CustomTableColumnType[],
  walkTestColumns: CustomTableColumnType[],
  patientTestReportColumns: CustomTableColumnType[],
) {
  const mapping: any = {
    patientNoSymptoms: {
      formatter: (data: any) => {
        const formatedText = data.map(
          (item: any) =>
            `<li style="text-align:left; padding: 5px 0px; font-size: 0.9rem">
            ${getSymptomsTextFormat(item, getmasterData)}
          </li>`,
        );

        return `
          <div style='display: flex; flex-direction: row; justify-content: space-between;'>
            <div style="margin: 0px; display: flex; flex-direction: column; justify-content: space-between; align-items: flex-start;">
              <div>
                Symptoms Reported
              </div>
              <div style='color: #33425B; font-size: 0.8rem;'>(${showDate})</div>
            </div>
            <div class='medical-event-tootlip-close-btn' style='cursor: pointer; padding-right: 5px;'>
              <svg 
                viewBox="0 0 20 20"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                style="width: 18px; height: 18px;"
              >
                <path d="M18.3 5.71a.9959.9959 0 0 0-1.41 0L12 10.59 7.11 5.7a.9959.9959 0 0 0-1.41 0c-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4"></path>
              </svg>
            </div>
          </div>
          <ul style="background: #9bb4cc40; border: 1px solid #9bb4cc; border-radius: 5px; padding: 10px; list-style: none; min-height: 1rem; max-height: 18.75rem; overflow: auto;">
            ${formatedText.join('')}
          </ul>
        `;
      },
    },
    patientSymptoms: {
      formatter: (data: any) => {
        const formatedText = data.map(
          (item: any) =>
            `<li style="text-align:left; padding: 5px 0px; font-size: 0.9rem">
            ${getSymptomsTextFormat(item, getmasterData)}
          </li>`,
        );

        return `
          <div style='display: flex; flex-direction: row; justify-content: space-between;'>
            <div style="margin: 0px; display: flex; flex-direction: column; justify-content: space-between; align-items: flex-start;">
              <div>
                Symptoms Reported
              </div>
              <div style='color: #33425B; font-size: 0.8rem;'>(${showDate})</div>
            </div>
            <div class='medical-event-tootlip-close-btn' style='cursor: pointer; padding-right: 5px;'>
              <svg 
                viewBox="0 0 20 20"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                style="width: 18px; height: 18px;"
              >
                <path d="M18.3 5.71a.9959.9959 0 0 0-1.41 0L12 10.59 7.11 5.7a.9959.9959 0 0 0-1.41 0c-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4"></path>
              </svg>
            </div>
          </div>
          <ul style="background: #9bb4cc40; border: 1px solid #9bb4cc; border-radius: 5px; padding: 10px; list-style: none; min-height: 1rem; max-height: 18.75rem; overflow: auto;">
            ${formatedText.join('')}
          </ul>
        `;
      },
    },
    medicationChange: {
      formatter: (data: any) => {
        let medicationChangeTable = '';
        medicationChangeTable = medicationChangeTable.concat(
          renderToString(<Typography style={{ fontWeight: 'bold', color: 'rgba(0, 0, 0, 0.87)', textAlign: 'left', marginTop: '5px' }}>Medications</Typography>),
        );
        medicationChangeTable = medicationChangeTable.concat(renderToString(<Divider />));
        medicationChangeTable = medicationChangeTable.concat(renderToString(<CustomTable data={data} columns={medicationColumnsAndNotesColumns.medicationColumns} />));

        medicationChangeTable = medicationChangeTable.concat(
          renderToString(<Typography style={{ fontWeight: 'bold', color: 'rgba(0, 0, 0, 0.87)', textAlign: 'left', marginTop: '15px' }}>Notes</Typography>),
        );
        medicationChangeTable = medicationChangeTable.concat(renderToString(<Divider />));
        medicationChangeTable = medicationChangeTable.concat(renderToString(<CustomTable data={data} columns={medicationColumnsAndNotesColumns.medicationNotesColumns} />));

        return `
          <div style='display: flex; flex-direction: row; justify-content: space-between;'>
            <div style="margin: 0px; display: flex; flex-direction: column; justify-content: space-between; align-items: flex-start;">
              <div>
                Prescription
              </div>
              <div style='color: #33425B; font-size: 0.8rem;'>(${showDate})</div>
            </div>
            <div class='medical-event-tootlip-close-btn' style='cursor: pointer; padding-right: 5px;'>
              <svg 
                viewBox="0 0 20 20"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                style="width: 18px; height: 18px;"
              >
                <path d="M18.3 5.71a.9959.9959 0 0 0-1.41 0L12 10.59 7.11 5.7a.9959.9959 0 0 0-1.41 0c-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4"></path>
              </svg>
            </div>
          </div>
          <div style="background: #9bb4cc40; border: 1px solid #9bb4cc; border-radius: 5px; padding: 0px 10px 10px 10px; margin: 0px; min-height: 1rem; max-height: 18.75rem; overflow: auto;">${medicationChangeTable}</div>
        `;
      },
    },
    medicationAcceptance: {
      formatter: (data: any) => {
        const formatedText = data.map(
          (item: any) =>
            `<li style="text-align:left; padding: 5px 0px; font-size: 0.9rem">
            ${getMedicationAcceptanceTextFormat(item, false)}
          </li>`,
        );

        return `
          <div style='display: flex; flex-direction: row; justify-content: space-between;'>
            <div style="margin: 0px; display: flex; flex-direction: column; justify-content: space-between; align-items: flex-start;">
              <div>
                Medication Accpectance
              </div>
              <div style='color: #33425B; font-size: 0.8rem;'>(${showDate})</div>
            </div>
            <div class='medical-event-tootlip-close-btn' style='cursor: pointer; padding-right: 5px;'>
              <svg 
                viewBox="0 0 20 20"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                style="width: 18px; height: 18px;"
              >
                <path d="M18.3 5.71a.9959.9959 0 0 0-1.41 0L12 10.59 7.11 5.7a.9959.9959 0 0 0-1.41 0c-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4"></path>
              </svg>
            </div>
          </div>
          <ul style="background: #9bb4cc40; border: 1px solid #9bb4cc; border-radius: 5px; padding: 10px;list-style: none; min-height: 1rem; max-height: 18.75rem; overflow: auto;">
            ${formatedText.join('')}
          </ul>
        `;
      },
    },
    labReports: {
      formatter: (data: any) => {
        const labReportsTable = renderToString(<CustomTable data={data} columns={vitalsColumns} />);

        return `
          <div style='display: flex; flex-direction: row; justify-content: space-between;'>
            <div style="margin: 0px; display: flex; flex-direction: column; justify-content: space-between; align-items: flex-start;">
              <div>
                Base Line Data
              </div>
              <div style='color: #33425B; font-size: 0.8rem;'>(${showDate})</div>
            </div>
            <div class='medical-event-tootlip-close-btn' style='cursor: pointer; padding-right: 5px;'>
              <svg 
                viewBox="0 0 20 20"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                style="width: 18px; height: 18px;"
              >
                <path d="M18.3 5.71a.9959.9959 0 0 0-1.41 0L12 10.59 7.11 5.7a.9959.9959 0 0 0-1.41 0c-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4"></path>
              </svg>
            </div>
          </div>
          <div style="background: #9bb4cc40; border: 1px solid #9bb4cc; border-radius: 5px; padding: 0px 10px 10px 10px; margin: 0px; min-height: 1rem; max-height: 18.75rem; overflow: auto;">${labReportsTable}</div>
        `;
      },
    },

    kccqHistory: {
      formatter: (data: any) => {
        const kccqHistoryTable = renderToString(<CustomTable data={data} columns={kccqColumns} />);

        return `
          <div style='display: flex; flex-direction: row; justify-content: space-between;'>
            <div style="margin: 0px; display: flex; flex-direction: column; justify-content: space-between; align-items: flex-start;">
              <div>
                KCCQ Score
              </div>
              <div style='color: #33425B; font-size: 0.8rem;'>(${showDate})</div>
            </div>
            <div class='medical-event-tootlip-close-btn' style='cursor: pointer; padding-right: 5px;'>
              <svg 
                viewBox="0 0 20 20"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                style="width: 18px; height: 18px;"
              >
                <path d="M18.3 5.71a.9959.9959 0 0 0-1.41 0L12 10.59 7.11 5.7a.9959.9959 0 0 0-1.41 0c-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4"></path>
              </svg>
            </div>
          </div>
          <div style="background: #9bb4cc40; border: 1px solid #9bb4cc; border-radius: 5px; padding: 0px 10px 10px 10px; margin: 0px; min-height: 1rem; max-height: 18.75rem; overflow: auto;">${kccqHistoryTable}</div>
        `;
      },
    },
    walkTestHistory: {
      formatter: (data: any) => {
        const walkTestHistoryTable = renderToString(<CustomTable data={data} columns={walkTestColumns} />);

        return `
          <div style='display: flex; flex-direction: row; justify-content: space-between;'>
            <div style="margin: 0px; display: flex; flex-direction: column; justify-content: space-between; align-items: flex-start;">
              <div>
                6 M.W.T
              </div>
              <div style='color: #33425B; font-size: 0.8rem;'>(${showDate})</div>
            </div>
            <div class='medical-event-tootlip-close-btn' style='cursor: pointer; padding-right: 5px;'>
              <svg 
                viewBox="0 0 20 20"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                style="width: 18px; height: 18px;"
              >
                <path d="M18.3 5.71a.9959.9959 0 0 0-1.41 0L12 10.59 7.11 5.7a.9959.9959 0 0 0-1.41 0c-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4"></path>
              </svg>
            </div>
          </div>
          <div style="background: #9bb4cc40; border: 1px solid #9bb4cc; border-radius: 5px; padding: 0px 10px 10px 10px; margin: 0px; min-height: 1rem; max-height: 18.75rem; overflow: auto;">${walkTestHistoryTable}</div>
        `;
      },
    },
    patientTestReports: {
      formatter: (data: any) => {
        const patientTestsReportsTable = renderToString(<CustomTable data={data} columns={patientTestReportColumns} />);

        return `
          <div style='display: flex; flex-direction: row; justify-content: space-between;'>
            <div style="margin: 0px; display: flex; flex-direction: column; justify-content: space-between; align-items: flex-start;">
              <div>
                Lab Reports
              </div>
              <div style='color: #33425B; font-size: 0.8rem;'>(${showDate})</div>
            </div>
            <div class='medical-event-tootlip-close-btn' style='cursor: pointer; padding-right: 5px;'>
              <svg 
                viewBox="0 0 20 20"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                style="width: 18px; height: 18px;"
              >
                <path d="M18.3 5.71a.9959.9959 0 0 0-1.41 0L12 10.59 7.11 5.7a.9959.9959 0 0 0-1.41 0c-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4"></path>
              </svg>
            </div>
          </div>
          <div style="background: #9bb4cc40; border: 1px solid #9bb4cc; border-radius: 5px; padding: 0px 10px 10px 10px; margin: 0px; min-height: 1rem; max-height: 18.75rem; overflow: auto;">${patientTestsReportsTable}</div>
        `;
      },
    },
  };

  const showDate = DateTime.fromJSDate(new Date(data.date)).toFormat('dd MMM yy');
  const value = Object.keys(data).filter((item: string) => item !== 'date')[0];

  return `<div style='width: auto; height: inherit; display: flex; flex-direction: column; align-items: center; padding: 8px; gap: 8px;'>
      <h6 style='margin: 0px; padding: 0px; font-size: 1em; text-align: center; font-weight: bold; color: #33425B;'>
        ${mapping[value]?.formatter(data[value])}
      </h6>
    </div>`;
}

export const _renderSide = (item: any, vitalsUnits: any, props?: { vitalsReferenceValues: any; vitalsCurrentValues: any }) => {
  const referenceDate = props?.vitalsReferenceValues[`${CHART_NAME_INIT_CURR_VALUE_MAP[item.chartName]}Initial`]?.date;
  const referenceDateObject = DateTime.fromISO(referenceDate).toJSDate();
  const vitalReferenceAge = DateTime.fromJSDate(referenceDateObject).toRelative() || 'NA';

  let vitalCurrentValue = props?.vitalsCurrentValues[`${CHART_NAME_INIT_CURR_VALUE_MAP[item.chartName]}Current`]?.value || '';
  const vitalCurrentUnit = props?.vitalsCurrentValues[`${CHART_NAME_INIT_CURR_VALUE_MAP[item.chartName]}Current`]?.unit || '';

  /* Getting Converted Current values from unitConverter */
  const formattedUnitObjectCurrent = getFormattedObjectForVitalsUnitConverter(
    CHART_NAME_UNIT_CONVERTER_MAPPING[item.chartName],
    CHART_NAME_UNIT_CONVERTER_MAPPING[item.chartName],
    Number(vitalCurrentValue),
    vitalCurrentUnit,
    'MANUAL_ENTRY',
  );
  const userUnitCurrent = vitalsUnits[USER_VITALS_UNIT_MAPPING.WEIGHT as keyof typeof vitalsUnits];
  const valueCurrent = vitalsUnitConverters(JSON.parse(JSON.stringify(formattedUnitObjectCurrent)), userUnitCurrent);
  vitalCurrentValue = valueCurrent.measurements[0].value || '';
  /* Getting Converted Current values from unitConverter */

  let vitalInitialValue = props?.vitalsReferenceValues[`${CHART_NAME_INIT_CURR_VALUE_MAP[item.chartName]}Initial`]?.value || '';
  const vitalInitialUnit = props?.vitalsReferenceValues[`${CHART_NAME_INIT_CURR_VALUE_MAP[item.chartName]}Initial`]?.unit || '';

  /* Getting Converted Initial values from unitConverter */
  const formattedUnitObjectInitial = getFormattedObjectForVitalsUnitConverter(
    CHART_NAME_UNIT_CONVERTER_MAPPING[item.chartName],
    CHART_NAME_UNIT_CONVERTER_MAPPING[item.chartName],
    Number(vitalInitialValue),
    vitalInitialUnit,
    'MANUAL_ENTRY',
  );
  const userUnitInitial = vitalsUnits[USER_VITALS_UNIT_MAPPING.WEIGHT as keyof typeof vitalsUnits];
  const valueInitial = vitalsUnitConverters(JSON.parse(JSON.stringify(formattedUnitObjectInitial)), userUnitInitial);
  vitalInitialValue = valueInitial.measurements[0].value || '';
  /* Getting Converted Initial values from unitConverter */

  const percentageDiffReferenceAndCurrentValue = (vitalCurrentValue && vitalInitialValue && calculatePercentageDiff(vitalInitialValue, vitalCurrentValue)) || '';
  const percentageDiffReferenceAndCurrentValueText = (vitalCurrentValue && vitalInitialValue && ` (${percentageDiffReferenceAndCurrentValue}%)`) || '';

  const vitalsFromDevice = CHART_NAME_DEVICE_MAP[item.chartName] && <>Device: {CHART_NAME_DEVICE_MAP[item.chartName]}</>;

  const sidebar = renderToString(
    <div
      key={item.chartName}
      // className='vitals-history-body-side-info'
      style={{
        width: '100%',
        height: '100%',

        display: 'grid',
        gridTemplateRows: 'auto auto auto',
        gap: 2,
        // justifyContent: 'space-evenly',
      }}>
      <div
        // className='vitals-history-body-side-info-current-value'
        style={{
          display: 'grid',
          gridTemplateColumns: 'auto auto',
          gap: 2,
          // justifyContent: 'space-evenly',
          // paddingLeft: '10px',
          alignContent: 'space-evenly',
        }}>
        <div
          // className='vitals-history-body-side-info-current-value-icon-label'
          style={{
            display: 'grid',
            gridTemplateColumns: 'auto auto auto',
            gap: 2,
            justifyContent: 'space-evenly',
            alignContent: 'space-evenly',
          }}>
          <span>
            {item.icon} {item.chartTitle}
          </span>
          {/* <Typography color='primary' variant='fontSemiBold16'>
            {item.chartTitle}
          </Typography> */}
          <span
            // color='info'
            style={{ fontSize: '10px', alignSelf: 'self-end' }}
            // variant='fontReg10'
            // className='vitals-history-body-side-info-unit'
          >
            {item.unit ? `(${item.unit})` : ''}
          </span>
        </div>
        <div
          style={{
            display: 'grid',
            gridTemplateRows: 'auto auto',
            gap: 2,
            justifyContent: 'space-evenly',
          }}>
          <span
          // className='vitals-history-body-side-info-vital-current-value'
          >
            {vitalCurrentValue ? customConvertFloatToInt(vitalCurrentValue) : vitalCurrentValue}
          </span>
          <span
          // className='vitals-history-body-side-info-current-value-percentage-diff'
          >
            {percentageDiffReferenceAndCurrentValueText}
          </span>
        </div>
      </div>

      <span className='tooltip-span' data-tooltip='Patient Enrolled' data-flow='down'>
        <div
          style={{ cursor: 'default', display: 'grid', gridTemplateColumns: '1fr 0.5fr', gap: 2, justifyContent: 'space-evenly', alignContent: 'space-evenly', height: '100%' }}
          // className='vitals-history-body-side-info-vital-reference-value-age-and-value'
        >
          <div
            // className='vitals-history-body-side-info-vital-reference-value-age-and-value-icon-reference-age'
            style={{ cursor: 'default', display: 'grid', gridTemplateColumns: 'auto auto', gap: 2, justifyContent: 'space-evenly', alignContent: 'space-evenly' }}>
            {SHOW_FLAG_AGE_VALUE_FOR[item.chartName] && <FlagTwoToneIcon className='vitals-history-body-side-info-vital-reference-value-age-and-value-icon-reference-age-icon' />}
            {SHOW_FLAG_AGE_VALUE_FOR[item.chartName] && vitalReferenceAge}
          </div>
          <div className='vitals-history-body-side-info-vital-reference-value-age-and-value-icon-reference-age-value' style={{ textAlign: 'center' }}>
            {vitalInitialValue ? customConvertFloatToInt(vitalInitialValue) : ''}
          </div>
        </div>
      </span>

      <div
        // className='vitals-history-body-side-info-device'
        style={{
          display: 'grid',
          gridTemplateColumns: 'auto',
          gap: 2,
          justifyContent: 'space-evenly',
          alignContent: 'space-evenly',

          textAlign: 'center',
        }}>
        {vitalsFromDevice}
      </div>
    </div>,
  );

  return sidebar;
};

export const addPlugins = (props: xAxisCorePropsInterfacePropsType, item: any, corePluginName: string, xAxisCore: any, width: number, height: number, medicalEventToolTip: any) => {
  if (item.plugin) {
    item.plugin.forEach((ele: { pluginName: string }) => {
      if (ele.pluginName === 'GRID') {
        const pluginName = item.chartName + 'Grid';
        // Drawing grid lines on focus-element spbYAxis
        xAxisCore.registerPlugin(
          pluginName,
          new GridPluginClass({
            height,
            width,
            xAxis: xAxisCore.core.xAxis,
            yAxis: xAxisCore.plugins[corePluginName].yAxis,
            xAxisScale: xAxisCore.core.xAxisScale as any,
            yAxisScale: xAxisCore.plugins[corePluginName].yAxisScale,
            yAxisTicks: xAxisCore.plugins[corePluginName].yAxisTicks,
            focusElement: xAxisCore.plugins[corePluginName].focusElement,
            chartData: item.data as any,
            // xAxisGridLineHeight: 15,
            referenceYLineValue: props.vitalsReferenceValues[`${CHART_NAME_INIT_CURR_VALUE_MAP[item.chartName]}Initial`]?.value || 0,
          }),
        );
        xAxisCore.plugins[pluginName].createD3GridElement();
        xAxisCore.plugins[pluginName].drawXAxisGrid();
        xAxisCore.plugins[pluginName].drawYAxisGrid();
        props.toggleReferenceLine && xAxisCore.plugins[pluginName].drawReferenceYLine();
      }

      if (ele.pluginName === 'DOT-TOOLTIP') {
        const pluginName = item.chartName + 'DOT';

        // Appending line dots and tooltip

        xAxisCore.registerPlugin(
          pluginName,
          new DotsAndTooltipsPluginClass({
            width: '100%',
            height,

            rootElement: props.id,
            focusElement: xAxisCore.plugins[corePluginName].focusElement,
            chartData: item.data,
            xAxisScale: xAxisCore.core.xAxisScale as any,
            yAxisScale: xAxisCore.plugins[corePluginName].yAxisScale,

            fillDotCustomFunc: (ele: any) => {
              // ['CRITICAL_LOW', 'CRITICAL_HIGH', 'NORMAL', 'WARNING_LOW', 'WARNING_HIGH']
              if (ele.value) {
                if (ele?.severity === 'CRITICAL_LOW' || ele?.severity === 'CRITICAL_HIGH') return 'red';
                if (ele?.severity === 'WARNING_LOW' || ele?.severity === 'WARNING_HIGH') return 'orange';
              }

              return 'black';
            },
            tooltipHTMLElement: (event: any) => {
              /**
               * Checking which browser is used
               * If Firefox - then event object has srcElement key
               * Other browsers has toElement key
               *  */
              const userAgent = navigator.userAgent;

              let dateTime;
              let value;

              if (userAgent.match(/firefox|fxios/i)) {
                dateTime = DateTime.fromJSDate(new Date(event?.srcElement?.__data__.timestamp)).toFormat('MMM dd, HH:mm');
                value = `${event.srcElement.__data__.value}`;
              } else {
                dateTime = DateTime.fromJSDate(new Date(event?.toElement?.__data__.timestamp)).toFormat('MMM dd, HH:mm');
                value = `${event.toElement.__data__.value}`;
              }

              return `<div style='width: inherit; height: inherit; display: flex; flex-direction: column; align-items: center; padding: 8px; gap: 8px;'>
                        <h6 style='margin: 0px; padding: 0px; font-size: 1em; text-align: center; font-weight: bold; color: #33425B;'>
                          ${dateTime} 
                        </h6>
                        <h6 style='margin: 0px; padding: 0px; font-size: 1em; text-align: center; font-weight: normal;'>
                          <span style='color: #33425B;'>${value}</span>
                        </h6>
                      </div>`;
            },
          } as any),
        );
        xAxisCore.plugins[pluginName].addDots();
        xAxisCore.plugins[pluginName].addTooltip();
        // xAxisCore.plugins[pluginName].addDotsLabels();
        xAxisCore.plugins[pluginName].appendTextMinMax();
        // xAxisCore.plugins[pluginName].zoomXAxis();

        xAxisCore.plugins[pluginName].createClipPath();
      }

      if (ele.pluginName === 'BAR') {
        // Appending Bar Chart
        const pluginName = item.chartName + 'BAR';
        const userAgent = navigator.userAgent;

        xAxisCore.registerPlugin(
          pluginName,
          new BarChartPluginClass({
            rootElement: props.id,
            chartData: item.data,
            focusElement: xAxisCore.plugins[corePluginName].focusElement,
            xAxisScale: xAxisCore.core.xAxisScale as any,
            yAxisScale: xAxisCore.plugins[corePluginName].yAxisScale,
            graphHeight: height,

            tooltipCustomFunc: function (event: any) {
              let pageX = event.pageX;
              let pageY = event.pageY;

              const customTooltip = document.querySelector('#svg-icon-tooltip');

              const customTooltipWidth = customTooltip?.getBoundingClientRect()?.width!;
              const customTooltipHeight = customTooltip?.getBoundingClientRect()?.height!;

              if (pageX + customTooltipWidth + 50 >= window.innerWidth) pageX = pageX - customTooltipWidth - 20;
              if (pageY + customTooltipHeight + 90 >= window.innerHeight) pageY = pageY - customTooltipHeight - 90;

              /**
               * Checking which browser is used
               * If Firefox - then event object has srcElement key
               * Other browsers has toElement key
               *  */

              // let tooltipData = [];
              let dateTime;
              let value;
              if (userAgent.match(/firefox|fxios/i)) {
                // tooltipData = event?.srcElement?.__data__ as any;
                dateTime = DateTime.fromJSDate(new Date(event?.srcElement?.__data__.timestamp)).toFormat('MMM dd, HH:mm');
                value = `${event.srcElement.__data__.value}`;
              } else {
                // tooltipData = event?.toElement?.__data__ as any;
                dateTime = DateTime.fromJSDate(new Date(event?.toElement?.__data__.timestamp)).toFormat('MMM dd, HH:mm');
                value = `${event.toElement.__data__.value}`;
              }

              const tooltipHtmlElement = `<div style='width: inherit; height: inherit; display: flex; flex-direction: column; align-items: center; padding: 8px; gap: 8px;'>
                        <h6 style='margin: 0px; padding: 0px; font-size: 1em; text-align: center; font-weight: bold; color: #33425B;'>
                          ${dateTime}
                        </h6>
                        <h6 style='margin: 0px; padding: 0px; font-size: 1em; text-align: center; font-weight: normal;'>
                          <span style='color: #33425B;'>${value}</span>
                        </h6>
                      </div>`;

              const tooltipElement = xAxisCore.plugins[pluginName].barTooltip
                ?.html(tooltipHtmlElement)
                .style('background-color', 'white')
                .style('border-radius', '4px')
                .style('box-shadow', '1px 1px 10px 1px gray')
                .style('left', `${pageX + 16}px`)
                .style('top', `${pageY + 16}px`);

              return tooltipElement;
            },

            // tooltipHTMLElement: (event: any) => {
            //   /**
            //    * Checking which browser is used
            //    * If Firefox - then event object has srcElement key
            //    * Other browsers has toElement key
            //    *  */
            //   const userAgent = navigator.userAgent;

            //   let dateTime;
            //   let value;

            //   if (userAgent.match(/firefox|fxios/i)) {
            //     dateTime = DateTime.fromJSDate(new Date(event?.srcElement?.__data__.timestamp)).toFormat('MMM dd, HH:mm');
            //     value = `${event.srcElement.__data__.value}`;
            //   } else {
            //     dateTime = DateTime.fromJSDate(new Date(event?.toElement?.__data__.timestamp)).toFormat('MMM dd, HH:mm');
            //     value = `${event.toElement.__data__.value}`;
            //   }

            //   return `<div style='width: inherit; height: inherit; display: flex; flex-direction: column; align-items: center; padding: 8px; gap: 8px;'>
            //             <h6 style='margin: 0px; padding: 0px; font-size: 1em; text-align: center; font-weight: bold; color: #33425B;'>
            //               ${dateTime}
            //             </h6>
            //             <h6 style='margin: 0px; padding: 0px; font-size: 1em; text-align: center; font-weight: normal;'>
            //               <span style='color: #33425B;'>${value}</span>
            //             </h6>
            //           </div>`;
            // },
          }),
        );
        xAxisCore.plugins[pluginName].createD3BarChartElement();
        xAxisCore.plugins[pluginName].addBars();
        xAxisCore.plugins[pluginName].addTooltip();
      }

      if (ele.pluginName === 'SVG-ICON-TOOLTIP') {
        const pluginName = item.chartName + 'SVG-ICON-TOOLTIP';
        // Append Svg Icon && Tooltip&& Text && Text
        xAxisCore.registerPlugin(
          pluginName,
          new SVGEmojiesAndTooltipPluginClass({
            rootElement: props.id,
            focusElement: xAxisCore.plugins[corePluginName].focusElement,
            chartData: item.data,
            medicalEventsData: [] as any,
            xAxisScale: xAxisCore.core.xAxisScale as any,
            yAxisScale: xAxisCore.plugins[corePluginName].yAxisScale,
            height,
          }),
        );
        xAxisCore.plugins[pluginName].createSvgEmojiesElement();
        // xAxisCore.plugins[pluginName].addSvgEmojies();
        // xAxisCore.plugins[pluginName].addSvgEmojiesTooltip();
      }

      if (ele.pluginName === 'APPEND-TEXT') {
        const pluginName = item.chartName + 'APPEND-TEXT';
        // Append Svg Icon && Tooltip && Text
        xAxisCore.registerPlugin(
          pluginName,
          new SVGIconAndTooltipPluginClass({
            rootElement: props.id,
            height,
            focusElement: xAxisCore.plugins[corePluginName].focusElement,
            chartData: item.data,
            xAxisScale: xAxisCore.core.xAxisScale as any,

            tooltipHTMLElement: (event: any) => {
              /**
               * Checking which browser is used
               * If Firefox - then event object has srcElement key
               * Other browsers has toElement key
               *  */
              const userAgent = navigator.userAgent;

              let dateTime;

              if (userAgent.match(/firefox|fxios/i)) {
                dateTime = DateTime.fromJSDate(new Date(event?.srcElement?.__data__.date)).toFormat('MMM dd');
              } else {
                dateTime = DateTime.fromJSDate(new Date(event?.toElement?.__data__.date)).toFormat('MMM dd');
              }

              return `<div style='width: auto; height: inherit; display: flex; flex-direction: column; align-items: center; padding: 8px; gap: 8px;'>
                        <h6 style='margin: 0px; padding: 0px; font-size: 1em; text-align: center; font-weight: bold; color: #33425B;'>
                          Lab Reports
                        </h6>
                        <h6 style='margin: 0px; padding: 0px; font-size: 1em; text-align: center; font-weight: normal;'>
                          <span style='color: #33425B;'>${dateTime}</span>
                        </h6>
                      </div>`;
            },
          }),
        );
        xAxisCore.plugins[pluginName].createSVGIconElement();
        xAxisCore.plugins[pluginName].appendText();
        xAxisCore.plugins[pluginName].addTooltip();
      }

      if (ele.pluginName === 'APPEND-SVG-ICON') {
        // Append Svg Icon && Tooltip && Text

        const pluginName = item.chartName + 'APPEND-SVG-ICON';
        const userAgent = navigator.userAgent;

        xAxisCore.registerPlugin(
          pluginName,
          new SVGIconAndTooltipPluginClass({
            rootElement: props.id,
            height,
            focusElement: xAxisCore.plugins[corePluginName].focusElement,
            chartData: item.data,
            xAxisScale: xAxisCore.core.xAxisScale as any,

            icons: medicalEventsIcons,
            iconsMappedKeys: medicalEventsIconsMappedKeys,

            tooltipCustomFunc: function (event: any) {
              let pageX = event.pageX;
              const pageY = event.pageY;

              const customTooltip = document.querySelector('#svg-icon-tooltip');

              const customTooltipWidth = customTooltip?.getBoundingClientRect()?.width!;
              // const customTooltipHeight = customTooltip?.getBoundingClientRect()?.height!;

              if (pageX + customTooltipWidth + 50 >= window.innerWidth) pageX = pageX - customTooltipWidth - 20;
              // if (pageY + customTooltipHeight + 20 >= window.innerHeight) pageY = pageY - customTooltipHeight + 20;

              /**
               * Checking which browser is used
               * If Firefox - then event object has srcElement key
               * Other browsers has toElement key
               *  */

              let tooltipData = [];
              if (userAgent.match(/firefox|fxios/i)) tooltipData = event?.srcElement?.__data__ as any;
              else tooltipData = event?.toElement?.__data__ as any;

              const tooltipElement = xAxisCore.plugins[pluginName]
                .svgIconTooltip!.html(medicalEventToolTip(tooltipData))
                .style('background-color', 'white')
                .style('border-radius', '4px')
                .style('box-shadow', '1px 1px 10px 1px gray')
                .style('left', `${pageX + 16}px`)
                .style('top', `${pageY + 16}px`)
                .on('click', xAxisCore.plugins[pluginName].showTooltip); // Toggle visibility on click

              tooltipElement
                .select('.medical-event-tootlip-close-btn') // Selecting the close button element and adding close event
                .on('click', (event: any) => {
                  event.stopPropagation();
                  xAxisCore.plugins[pluginName].hideTooltip();
                });

              // Add a document-level click event listener
              const closeTooltipOnClickOutside = (event: any) => {
                const clickedElement = event.target;
                // Check if the clicked element is outside the tooltip and its trigger
                if (!tooltipElement.node().contains(clickedElement) && !event.target.closest('.svg-rect')) {
                  // Add class of your trigger element
                  xAxisCore.plugins[pluginName].hideTooltip();
                  document.removeEventListener('click', closeTooltipOnClickOutside);
                }
              };
              document.addEventListener('click', closeTooltipOnClickOutside);

              return tooltipElement;
            },
          }),
        );
        xAxisCore.plugins[pluginName].createSVGIconElement();
        xAxisCore.plugins[pluginName].appendSVGIcon();
        xAxisCore.plugins[pluginName].addTooltip('INTERACTABLE');
      }

      if (ele.pluginName === 'MEDIAN-LINE' && props.toggleMedianLine) {
        const pluginName = item.chartName + 'LINE';

        let mediansPerDay;
        if (item.chartName === 'WEIGHT') {
          mediansPerDay = getMaxDayWise(item.data);
        } else {
          mediansPerDay = calculateMedians(item.data);
        }

        xAxisCore.registerPlugin(
          pluginName,
          new LinePluginClass({
            rootElement: props.id,
            chartData: mediansPerDay,
            focusElement: xAxisCore.plugins[corePluginName].focusElement,
            xAxisScale: xAxisCore.core.xAxisScale as any,
            yAxisScale: xAxisCore.plugins[corePluginName].yAxisScale,
          }),
        );

        xAxisCore.plugins[pluginName].createD3LineElement();
        // xAxisCore.plugins[pluginName].drawLine('#C15350');
        xAxisCore.plugins[pluginName].drawLine('#3498db');
      }
    });
  }
};

export const getVitalsColumns = (props: { appTheme: any }) => {
  const { appTheme } = props;

  const vitalsColumns: CustomTableColumnType[] = [
    {
      id: 'vital-name',
      label: '',
      hiddenHeader: true,
      cellAlignment: 'left',
      cellFormatter: (vital: { name: string }, index: number) => {
        const vitalName = LAB_REPORTS_MAP_LABEL[vital?.name] || vital?.name || '';
        return <div style={{ ...appTheme.typography?.fontBold14, color: appTheme.palette.text.primary, textAlign: 'left' }}>{vitalName}</div>;
      },
    },
    {
      id: 'vital-value',
      label: '',
      hiddenHeader: true,
      cellAlignment: 'left',
      cellFormatter: (vital: { value: string | number; unit: string }, index: number) => {
        return <div style={{ ...appTheme.typography?.fontReg14, color: appTheme.palette.text.primary, textAlign: 'left' }}>{vital?.value + ' ' + vital?.unit}</div>;
      },
    },
  ];

  return vitalsColumns;
};

export const getKccqColumns = (props: { appTheme: any }) => {
  const { appTheme } = props;

  const kccqColumns: CustomTableColumnType[] = [
    {
      id: 'clinical-summary-score',
      label: 'Clinical score',
      minWidth: '110px',
      cellFormatter: (data: { date: Date; clinicalSummaryScore: number | string; totalSymptomScore: number | string; overallSummaryScore: number | string }) => {
        return <div style={{ ...appTheme.typography?.fontReg14, color: appTheme.palette.text.primary }}>{data.clinicalSummaryScore}</div>;
      },
    },
    {
      id: 'clinical-summary-score',
      label: 'Overall score',
      minWidth: '115px',
      cellFormatter: (data: { date: Date; clinicalSummaryScore: number | string; totalSymptomScore: number | string; overallSummaryScore: number | string }) => {
        return <div style={{ ...appTheme.typography?.fontReg14, color: appTheme.palette.text.primary }}>{data.overallSummaryScore}</div>;
      },
    },
    {
      id: 'clinical-summary-score',
      label: 'Total score',
      minWidth: '100px',
      cellFormatter: (data: { date: Date; clinicalSummaryScore: number | string; totalSymptomScore: number | string; overallSummaryScore: number | string }) => {
        return <div style={{ ...appTheme.typography?.fontReg14, color: appTheme.palette.text.primary }}>{data.totalSymptomScore}</div>;
      },
    },
  ];

  return kccqColumns;
};

export const getWalkTestColumns = (props: { appTheme: any }) => {
  const { appTheme } = props;

  const kccqColumns: CustomTableColumnType[] = [
    {
      id: 'clinical-summary-score',
      label: 'Walk test',
      minWidth: '90px',
      cellFormatter: (data: { date: Date; finalSPO2: number | string; initialSPO2: number | string; value: number | string }) => {
        return <div style={{ ...appTheme.typography?.fontReg14, color: appTheme.palette.text.primary }}>{data.value}</div>;
      },
    },
    {
      id: 'clinical-summary-score',
      label: 'Initial SPO2',
      minWidth: '100px',
      cellFormatter: (data: { date: Date; finalSPO2: number | string; initialSPO2: number | string; value: number | string }) => {
        return <div style={{ ...appTheme.typography?.fontReg14, color: appTheme.palette.text.primary }}>{data.initialSPO2}</div>;
      },
    },
    {
      id: 'clinical-summary-score',
      label: 'Final SPO2',
      minWidth: '100px',
      cellFormatter: (data: { date: Date; finalSPO2: number | string; initialSPO2: number | string; value: number | string }) => {
        return <div style={{ ...appTheme.typography?.fontReg14, color: appTheme.palette.text.primary }}>{data.finalSPO2}</div>;
      },
    },
  ];

  return kccqColumns;
};

export const patientTestReportColumns = (props: any) => {
  const { appTheme } = props;

  const patientTestReportColumns: CustomTableColumnType[] = [
    {
      id: 'test-name',
      label: <div style={{ ...appTheme.typography?.fontBold14, color: appTheme.palette.text.primary, textAlign: 'left' }}>Investigations</div>,
      minWidth: '150px',
      valign: 'top',
      cellFormatter: (data) => {
        return <div style={{ ...appTheme.typography?.fontSemiBold14, color: appTheme.palette.text.primary, textAlign: 'left' }}>{data.name}</div>;
      },
    },
    {
      id: 'test-list',
      label: <div style={{ ...appTheme.typography?.fontBold14, color: appTheme.palette.text.primary, textAlign: 'left' }}>Results</div>,
      minWidth: '300px',
      cellFormatter: (data) => {
        return (
          <>
            <table style={{ ...appTheme.typography?.fontReg14, color: appTheme.palette.text.primary, textAlign: 'left' }}>
              <tbody>
                {data.tests?.map((item: { name: string; value: string; units: string }) => {
                  return (
                    <tr key={item.name}>
                      <td>{item.name} - </td>
                      <td>{item.value}</td>
                      <td>{item.units}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <br />
          </>
        );
      },
    },
  ];

  return patientTestReportColumns;
};
