/** @format */

import _ from 'underscore';

import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import InputBase from '@mui/material/InputBase';
import Typography from '@mui/material/Typography';
import ButtonGroup from '@mui/material/ButtonGroup';
import useTheme from '@mui/material/styles/useTheme';
import Slider, { SliderProps } from '@mui/material/Slider';
import ClickAwayListener from '@mui/material/ClickAwayListener';

import { Theme } from '@mui/system';
import styled from '@emotion/styled';
import { SxProps } from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

import { PatientRuleVitalsOptionsMarksType } from '../../types/PatientRule.types';

interface Custom2PointRangeSliderProps {
  minRange: number;
  maxRange: number;

  tooltipEditable?: boolean;

  marks?: PatientRuleVitalsOptionsMarksType;

  step?: number | null;

  defaultRange: [number, number];

  value: number | number[];
  handleChangeValue: (value: number | number[]) => void;

  disabled?: boolean;
}

const Custom2PointRangeSlider: React.FunctionComponent<Custom2PointRangeSliderProps> = (props) => {
  const appTheme = useTheme();

  const [value, setValue] = React.useState<number | number[]>(props.value);

  const [isTooltipEditable, setIsToolTipEditable] = React.useState<{ [key: string]: boolean }>({});
  const [isTooltipInputable, setIsTooltipInputable] = React.useState<{ [key: string]: boolean }>({});

  const [zoomSliderThumbClasses, setZoomSliderThumbClasses] = React.useState<Parameters<ReturnType<typeof styled>>[1]>();

  const disableSliderThumbRef = React.useRef<boolean>(false);
  const isValueEditableTimeoutRef = React.useRef<any>(null);
  const isParenetTimerRef = React.useRef<any>(null);

  /* Function to calculate percentage based on current value
   * @param value: number
   * @returns number
   */
  const calculatePercentage = (val: number) => {
    /* Calculating percentage
     * Explanation:
     * Consider the range is between 40 and 120 (minRange = 40 and maxRange 120)
     * And current value is 50
     * Subtract current value with minRange, it will normalize the value and bring it between 0 and 100
     * Then divide by the total range (maxRange - minRange), ite will normalize the value and bring it between 0 and 100
     * Finally multiply by 100 to get the percentage
     */
    return ((val - props.minRange) / (props.maxRange - props.minRange)) * 100;
  };

  // Initial calculation of percentages based on the default values
  const [perc, setPerc] = React.useState(Array.isArray(props.value) ? props.value.map(calculatePercentage) : [calculatePercentage(props.value)]);

  React.useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  const updateParentState = (value: number | number[]) => {
    if (isParenetTimerRef.current) {
      clearTimeout(isParenetTimerRef.current);
    }

    isParenetTimerRef.current = setTimeout(() => {
      props.handleChangeValue(value);
    }, 500);
  };

  const toggleEditData = (key: string | number) => {
    setIsToolTipEditable((prev) => ({ ...prev, [key]: !prev[key] }));
  };

  const restrictThumbsToCrossEachOther = (value: number[], activeThumbIndex: number, awayDistance: number) => {
    const minDistance = awayDistance;
    const newValueArray = [...value];

    if (minDistance) {
      // Adjust the position of the active thumb while maintaining `minDistance`
      if (activeThumbIndex === 0) {
        // First thumb => Ensure it doesn't overlap the second thumb
        newValueArray[0] = Number(Math.min(newValueArray[0], newValueArray[1] - minDistance).toFixed(2));
      } else if (activeThumbIndex === 1) {
        // Second thumb => Ensure it stays between the first and third thumbs
        newValueArray[1] = Number(Math.max(newValueArray[1], newValueArray[0] + minDistance));
      }
    }

    return newValueArray;
  };

  const handleChangeRangeValue = (event: Event, value: number | number[], activeThumbIndex: number) => {
    if (props.disabled) {
      return;
    }

    if (Array.isArray(value)) {
      value = restrictThumbsToCrossEachOther(value, activeThumbIndex, props.step ? props.step : 1);
    }

    if (Array.isArray(value)) {
      const newPerc = value.map(calculatePercentage);
      setPerc(newPerc);
    }

    if (!disableSliderThumbRef.current) {
      setValue(value);
      updateParentState(value);
    }
  };

  const handleClickTooltipInputEdit = (key: string | number) => {
    setIsTooltipInputable((prev) => ({ ...prev, [key]: prev[key] ? !prev[key] : true }));
  };

  const handleChangeInput = (newValue: number, index: any, param: any = 0) => {
    let propsValue = value;
    if (Array.isArray(propsValue)) {
      if (!_.isNaN(param)) {
        propsValue[index] = Number(newValue) + param;
      }

      /* If value entered is below the minRange or above maxRange, then it will be set to the minRange or maxRange */
      if (newValue <= props.minRange) {
        propsValue[index] = props.minRange;
      }

      if (newValue >= props.maxRange) {
        propsValue[index] = props.maxRange;
      }

      propsValue = restrictThumbsToCrossEachOther(propsValue, index, props.step ? props.step : 1);
    } else {
      propsValue = Number(newValue) + param;
    }
    setValue(propsValue);
    updateParentState(propsValue);
  };

  const zoomSliderThumbIfOverlap = (per: number[]) => {
    // Slider overlap - will get scaled 3,4,5,6
    //                                  0,1,2,3

    const thumbZoomClasses: Parameters<ReturnType<typeof styled>>[1] = {};

    if (per[3] - per[2] < 10) {
      thumbZoomClasses['& .MuiSlider-thumb:nth-child(6)'] = {
        '& > .MuiSlider-valueLabelOpen': {
          transform: 'translate(0px, -100px)',
        },

        '[class*="MuiSlider-valueLabel"]::before': {
          height: '75px',
          transform: 'translate(-50%, 100%) rotate(180deg)',
        },
      };
    }

    if (per[2] - per[1] < 10) {
      thumbZoomClasses['& .MuiSlider-thumb:nth-child(4)'] = {
        '& > .MuiSlider-valueLabelOpen': {
          transform: 'translate(0px, -100px)',
        },

        '[class*="MuiSlider-valueLabel"]::before': {
          height: '75px',
          transform: 'translate(-50%, 100%) rotate(180deg)',
        },
      };
    }

    if (per[1] - per[0] < 10) {
      thumbZoomClasses['& .MuiSlider-thumb:nth-child(4)'] = {
        '& > .MuiSlider-valueLabelOpen': {
          transform: 'translate(0px, -100px)',
        },

        '[class*="MuiSlider-valueLabel"]::before': {
          height: '75px',
          transform: 'translate(-50%, 100%) rotate(180deg)',
        },
      };
    }

    thumbZoomClasses['& .MuiSlider-thumb:nth-child(3)'] = {
      zIndex: 1,
    };

    thumbZoomClasses['& .MuiSlider-thumb:nth-child(5)'] = {
      zIndex: 1,
    };

    setZoomSliderThumbClasses(thumbZoomClasses);
  };

  React.useEffect(() => {
    Object.keys(isTooltipEditable).forEach((key) => {
      if (isTooltipEditable[key]) {
        if (isValueEditableTimeoutRef.current) {
          clearTimeout(isValueEditableTimeoutRef.current);
        }
        isValueEditableTimeoutRef.current = setTimeout(() => {
          toggleEditData(key);
        }, 5000);
      } else {
        if (isValueEditableTimeoutRef.current) {
          clearTimeout(isValueEditableTimeoutRef.current);
        }
      }
    });

    if (Array.isArray(value)) {
      const newPerc = value.map(calculatePercentage);
      setPerc(newPerc);
    }

    zoomSliderThumbIfOverlap(perc);
  }, [isTooltipEditable, value, props.tooltipEditable]);

  const _renderSliderToolTop = (value: number, index: number) => {
    const handleClose = () => {
      if (props.tooltipEditable) {
        toggleEditData(index);
      }
    };

    if (!isTooltipEditable[index]) {
      return (
        <Box onClick={handleClose} sx={{ width: 'min-content', /* backgroundColor: '#f5f9fe', */ padding: '2px 4px', color: 'GrayText' }}>
          {props.step ? value.toFixed(2) : value}
        </Box>
      );
    }

    let inputComponent = (
      <InputBase readOnly type='number' onDoubleClick={() => handleClickTooltipInputEdit(index)} sx={{ width: '80px', background: 'inherit' }} value={props.step ? value.toFixed(2) : value} />
    );

    if (isTooltipInputable[index]) {
      inputComponent = (
        <ClickAwayListener onClickAway={() => handleClickTooltipInputEdit(index)}>
          <InputBase
            autoFocus
            type='number'
            sx={{ width: '80px', background: 'inherit' }}
            defaultValue={value.toFixed(2)}
            // onChange={(event) => handleChangeInput(Number(event.target?.value), index, 0)}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                handleChangeInput(Number((event.target as any).value), index, 0);
              }
            }}
            onBlur={(event) => handleChangeInput(Number(event.target?.value), index, 0)}
          />
        </ClickAwayListener>
      );
    }

    return (
      <ClickAwayListener onClickAway={handleClose}>
        <ButtonGroup onMouseEnter={() => (disableSliderThumbRef.current = true)} onMouseLeave={() => (disableSliderThumbRef.current = false)} variant='text' color='primary'>
          <Button
            onClick={() => handleChangeInput(value, index, -props.step! || -1)}
            sx={{
              border: 'none !important',
            }}>
            <RemoveIcon />
          </Button>
          {inputComponent}
          <Button onClick={() => handleChangeInput(value, index, props.step || 1)}>
            <AddIcon />
          </Button>
        </ButtonGroup>
      </ClickAwayListener>
    );
  };

  const _renderRangeDisplayInfo = ({
    values,
    label,
    disabled,
    dividerBorderColor,
    valuesLabels,
  }: {
    values: Array<Array<number>>;
    label?: string;
    disabled?: boolean;
    dividerBorderColor?: string;
    valuesLabels?: Array<Array<string>>;
  }) => {
    return (
      <Grid item display={'flex'} minWidth={150}>
        <Grid item mr={1}>
          <Divider orientation='vertical' sx={{ borderWidth: 3, borderColor: dividerBorderColor, height: 20, opacity: disabled ? 0.38 : 100 }} />
        </Grid>

        <Grid item>
          <Typography variant='fontReg14' color={disabled ? 'GrayText' : 'text'}>
            {label}
          </Typography>
          <List>
            <ListItem disablePadding>
              <Typography variant='fontReg14' color={disabled ? 'GrayText' : 'primaryLight'}>
                {values.map((item, index) => {
                  return (
                    <>
                      {index !== 0 && <br />}•{' '}
                      <span key={index}>
                        {valuesLabels && valuesLabels[index] && valuesLabels[index][0]} {item[0]}
                        {item.length > 1 && ' to'}
                        {valuesLabels && valuesLabels[index] && valuesLabels[index][1]} {item[1]}
                      </span>
                    </>
                  );
                })}
              </Typography>
            </ListItem>
          </List>
        </Grid>
      </Grid>
    );
  };

  const rangeSliderDisplayInfos = [
    {
      values: Array.isArray(value) ? [[props.minRange, value[0]]] : [],
      label: 'Normal Range',
      disabled: props.disabled,
      dividerBorderColor: '#36B37E',
    },
    {
      values: Array.isArray(value) ? [[value[0], value[1]]] : [],
      label: 'Warning Ranges',
      disabled: props.disabled,
      dividerBorderColor: '#FFAB00',
    },
    {
      label: 'Critical Ranges',
      values: Array.isArray(value) ? [[value[1]]] : [],
      valuesLabels: [['Greater than']],
      dividerBorderColor: '#FF5630',
      disabled: props.disabled,
    },
  ];

  const sliderProps: SliderProps = {
    id: 'tract',
    valueLabelDisplay: 'on',
    value,
    onChange: handleChangeRangeValue,
    valueLabelFormat: _renderSliderToolTop,
    sx: {
      cursor: props.disabled ? 'not-allowed' : 'pointer',
      color: props.disabled ? 'GrayText' : 'primary',
      marginTop: 10,

      // Additional css for slider
      '& .MuiSlider-track': {
        backgroundColor: 'unset',
        borderWidth: '0px',
      },

      ...(zoomSliderThumbClasses as SxProps<Theme>),

      '& .MuiSlider-thumb': {
        '&:nth-child(odd)': {
          border: `3px solid ${props.disabled ? '#AFB3CC' : appTheme.palette.customColor.primary}`,
          backgroundColor: 'white',

          '& > span': {
            background: 'white',
            border: `2px solid ${props.disabled ? '#AFB3CC' : appTheme.palette.customColor.primary}`,
            borderRadius: '6px',
            width: 'auto',
            height: 'auto',
            padding: 0,

            // translateY:
            transform: 'translateY(-125%) scale(1)',
          },
          '& > span .MuiSlider-valueLabelLabel > div': {
            fontSize: '1.4em',
            color: props.disabled ? 'GrayText' : appTheme.palette.customColor.text,
          },
        },
        '&:nth-child(even)': {
          border: `3px solid ${props.disabled ? '#AFB3CC' : appTheme.palette.customColor.primary}`,
          backgroundColor: 'white',

          '& > span': {
            background: 'white',
            border: `2px solid ${props.disabled ? '#AFB3CC' : appTheme.palette.customColor.primary}`,
            borderRadius: '6px',
            width: 'auto',
            height: 'auto',
            padding: 0,

            // translateY:
            transform: 'translateY(-125%) scale(1)',
          },
          '& > span .MuiSlider-valueLabelLabel > div': {
            fontSize: '1.4em',
            color: props.disabled ? 'GrayText' : appTheme.palette.customColor.text,
          },
        },

        '[class*="MuiSlider-valueLabel"]::before': {
          position: 'absolute',
          content: '""',
          width: '2px',
          height: '20px',
          transform: 'translate(-50%, 100%) rotate(180deg)',
          backgroundColor: props.disabled ? '#AFB3CC' : appTheme.palette.customColor.primary,
          bottom: '0px',
          left: '50%',
        },
      },
      '& .MuiSlider-mark': {
        background: 'none',
      },
      '& .MuiSlider-rail': {
        borderRadius: '0px',
        opacity: props.disabled ? 0.38 : 100,
        background: `linear-gradient(
              to right,
              #36B37E 0%,
              #36B37E ${perc[0]}%,
              #FFAB00 ${perc[0]}%,
              #FFAB00 ${perc[0]}%,
              #FFAB00 ${perc[0]}%,
              #FFAB00 ${perc[1]}%,
              #FFAB00 ${perc[1]}%,
              #FFAB00 ${perc[1]}%,
              #FF5630 ${perc[1]}%,
              #FF5630 100%
            )`,
      },
    },
    min: props.minRange,
    max: props.maxRange,
    marks: props.marks,
    step: props.step,
  };

  if (Array.isArray(value)) {
    return (
      <>
        <Grid container mt={4}>
          <Grid item>
            <Divider orientation='vertical' sx={{ borderWidth: 1, borderColor: 'grey.350', height: 50, marginTop: 8.7 }} />
          </Grid>
          <Grid item xs>
            <Slider {...sliderProps} />
          </Grid>
          <Grid item>
            <Divider orientation='vertical' sx={{ borderWidth: 1, borderColor: 'grey.350', height: 50, marginTop: 8.7 }} />
          </Grid>
        </Grid>

        <Grid container mt={4} direction={'row'} gap={9} justifyContent={'center'}>
          {rangeSliderDisplayInfos.map((ele) => {
            return _renderRangeDisplayInfo(ele);
          })}
        </Grid>
      </>
    );
  }

  return <Slider {...sliderProps} />;
};

Custom2PointRangeSlider.defaultProps = {
  tooltipEditable: true,
};

export default Custom2PointRangeSlider;
