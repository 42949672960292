/** @format */

import AxiosInstance from './index';
import CONFIG from '../config/config';

export const getPatientNotes = async (payload: { sourceId: string; limit: number; offset?: number; createdBy?: string; search?: string; type?: string; startDate?: string; endDate?: string }) => {
  const { sourceId, limit, offset, createdBy, search, type, startDate, endDate } = payload;

  const res = await AxiosInstance({
    method: 'GET',
    baseURL: CONFIG.patientApiUrl,
    url: `patients/${sourceId}/notes`,
    params: {
      limit,
      offset,
      createdBy,
      search,
      type,
      startDate,
      endDate,
    },
  });

  return res.data || {};
};

export const addPatientNote = async (sourceId: string, notePayload: any) => {
  const res = await AxiosInstance({
    method: 'POST',
    baseURL: CONFIG.patientApiUrl,
    url: `patients/${sourceId}/notes`,
    data: {
      ...notePayload,
    },
  });

  return res.data || {};
};

export const updatePatientNote = async (sourceId: string, noteId: string, notePayload: any) => {
  const res = await AxiosInstance({
    method: 'PATCH',
    baseURL: CONFIG.patientApiUrl,
    url: `patients/${sourceId}/notes/${noteId}`,
    data: {
      ...notePayload,
    },
  });

  return res.data || {};
};

export const deletePatientNote = async (sourceId: string, noteId: string) => {
  const res = await AxiosInstance({
    method: 'DELETE',
    baseURL: CONFIG.patientApiUrl,
    url: `patients/${sourceId}/notes/${noteId}`,
  });

  return res.data || {};
};

export const getOrgUsers = async (userType: string) => {
  const res = await AxiosInstance({
    method: 'GET',
    baseURL: CONFIG.careWebAppApiUrl,
    url: 'organizations/users',
    params: {
      userType,
    },
  });

  return res.data || {};
};

export const getOrganizationUsersByOrgId = async (organizationId: string) => {
  const res = await AxiosInstance({
    method: 'GET',
    baseURL: CONFIG.careWebAppApiUrl,
    url: `organizations/${organizationId}/users`,
  });

  return res.data || {};
};

export const getOrganizationParnteredUsers = async () => {
  const res = await AxiosInstance({
    method: 'GET',
    baseURL: CONFIG.careWebAppApiUrl,
    url: 'organizations/partners/users',
  });

  return res.data || {};
};
