/** @format */

import AxiosInstance from './index';
import CONFIG from '../config/config';
import { PatientVitalsReadingType } from '../types/PatientVitals.types';

export const fetchPatientFluidIntake = async (sourceId: string) => {
  const res = await AxiosInstance({
    method: 'GET',
    baseURL: CONFIG.patientApiUrl,
    url: `/patients/${sourceId}/fluid-intake/latest`,
  });

  return res.data || {};
};

export const fetchPatientVitalsByType = async (sourceId: string, vitalsType: string, startDate: string, endDate: string) => {
  const res = await AxiosInstance({
    method: 'GET',
    baseURL: CONFIG.patientApiUrl,
    url: `/patients/${sourceId}/vitals/${vitalsType}?startTime=${startDate}&endTime=${endDate}`,
  });

  return res.data || {};
};

export const fetchPatientVitalsByRange = async (sourceId: string, startDate: string, endDate: string) => {
  const res = await AxiosInstance({
    method: 'GET',
    baseURL: CONFIG.patientApiUrl,
    url: `/patients/${sourceId}/vitals?startTime=${startDate}&endTime=${endDate}`,
  });

  return res.data || {};
};

export const fetchPatientVitalsByRangeGraph = async (sourceId: string, startDate: string, endDate: string, sourceType: string) => {
  const res = await AxiosInstance({
    method: 'GET',
    baseURL: CONFIG.patientApiUrl,
    url: `/patients/${sourceId}/vitals/graph`,
    params: {
      startDate,
      endDate,
      sourceType,
    },
  });

  return res.data || {};
};

export const fetchPatientLatestVitalsApi = async (sourceId: string, additionalHeaders: any) => {
  const res = await AxiosInstance({
    method: 'GET',
    baseURL: CONFIG.patientApiUrl,
    headers: {
      ...additionalHeaders,
    },
    url: `/patients/${sourceId}/vitals/latest`,
  });

  return res.data || {};
};

export const fetchPatientLatestSymptoms = async (sourceId: string) => {
  const res = await AxiosInstance({
    method: 'GET',
    baseURL: CONFIG.patientApiUrl,
    url: `/patients/${sourceId}/symptoms/latest`,
  });

  return res.data || {};
};

export const uploadPatientLabReports = async (patientSourceId: string, patientVitals: PatientVitalsReadingType[]) => {
  const res = await AxiosInstance({
    method: 'post',
    baseURL: CONFIG.patientApiUrl,
    url: `/patients/${patientSourceId}/vitals/lab-Reports`,
    data: patientVitals,
  });

  return res.data || {};
};
