/** @format */

import { AxiosRequestConfig } from 'axios';

import AxiosInstance from './index';

import config from '../config/config';
import { UserSettingPresetType } from '../types/UserSetting.types';

export const fetchSystemDefaultConfigRule = async () => {
  const params: AxiosRequestConfig = {
    baseURL: config.patientApiUrl,
    url: '/config/rules/default',
  };

  const res = await AxiosInstance(params);
  return res.data || {};
};

export const createOrganizationUserConfig = async (configType: string, payload: UserSettingPresetType[]) => {
  const params: AxiosRequestConfig = {
    method: 'POST',
    baseURL: config.careWebAppApiUrl,
    url: 'organizations/users/config',
    data: {
      payload,
      configType,
    },
  };

  const res = await AxiosInstance(params);
  return res.data || {};
};

export const updateOrganizationUserConfig = async (configType: string, payload: UserSettingPresetType[]) => {
  const params: AxiosRequestConfig = {
    method: 'PATCH',
    baseURL: config.careWebAppApiUrl,
    url: 'organizations/users/config',
    data: {
      payload,
      configType,
    },
  };

  const res = await AxiosInstance(params);
  return res.data || {};
};

export const deleteOrganizationUserConfig = async (configType: string) => {
  const params: AxiosRequestConfig = {
    method: 'DELETE',
    baseURL: config.careWebAppApiUrl,
    url: 'organizations/users/config',
    data: {
      configType,
    },
  };

  const res = await AxiosInstance(params);
  return res.data || {};
};

export const fetchOrganizationUserConfig = async () => {
  const params: AxiosRequestConfig = {
    baseURL: config.careWebAppApiUrl,
    url: 'organizations/users/config',
  };

  const res = await AxiosInstance(params);
  return res.data || {};
};
