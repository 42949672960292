/** @format */

import React from 'react';
import { LoadingButton } from '@mui/lab';
import validator from '@rjsf/validator-ajv8';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Grid, Typography, useTheme } from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

// Components.
import Form from '@rjsf/mui';
import CustomLoading from '../../components/common/custom-loading/CustomLoading';
import UserPermissionPage from '../../components/common/user-permission/UserPermissionPage';
import { TitleFieldTemplate, FieldErrorTemplate } from '../../components/common/react-json-schema';
import { ConfirmationDialog } from '../../components/common/confirmation-dialog/ConfirmationDialog';
import ProfileSettingPanelSidebar from '../../components/patient-profile/profile-setting-panel/ProfileSettingPanel';

import SettingsIcon from '../../assets/SettingsIcon';

// Custom Hooks.
import { PatientEnrolmentContext } from './PatientEnrolmentContext';
import { UsePatientFormFieldsProps, usePatientFormFields } from '../../hooks/usePatientFormFields';

import { UserSessionContext } from '../../context/UserSession';

// Constants.
import { APP_ROLES_DOCTORS } from '../../constants/user-role';

// SCSS.
import './PatientEnrollment.scss';
import NoAccessPage from '../NoAccessPage';

export const PatientEnrolment = () => {
  const {
    organizatoinFields,

    tabPhysicianRef,
    tabPatientBasicDetailsRef,
    tabPatientMedicalHistoryRef,
    buttonRef,

    editDetails,
    handleClickEditDetails,

    loading,
    isApiCalling,

    allDoctors,
    formData,
    onChangeForm,

    pathname,

    customValidate,
    transformErrors,
    customElementScollToView,
    onSubmit,

    showApproveButton,

    isEditTCPMedications,
    setIsEditTCPMedications,
    organizationMedications,

    showCancelChangesDialog,
    onClickCancelSavedChanges,
    onClickHideCancelChangesConfirmationDialog,
    onClickShowCancelChangesConfirmationDialog,

    submitApproveIsLoading,
    handleClickSubmitApprove,

    openSettingDrawer,
    handleCloseSettingDrawer,
    handleClickOpenSettingDrawer,
    handleSubmitAttachments,
  } = React.useContext(PatientEnrolmentContext);

  const { userSession, partnerOrganizationId } = React.useContext(UserSessionContext);

  const appTheme = useTheme();
  const navigate = useNavigate();
  const useSchemaProps: UsePatientFormFieldsProps = {
    editDetails,
    handleClickEditDetails,
    tabPhysicianRef,
    tabPatientBasicDetailsRef,
    tabPatientMedicalHistoryRef,

    allDoctors,
    enrolmentConfig: {
      enrolmentConfig: {
        patientBasic: organizatoinFields.patientBasic,
        vitals: organizatoinFields.vitals,
      },

      vitalsUnitConfig: {
        vitalsUnit: organizatoinFields.vitalsUnit,
      },
    },

    formData,
    onChangeFormData: onChangeForm,

    isEditTCPMedications,
    setIsEditTCPMedications,
    organizationMedications,
    handleSubmitAttachments,
  };

  const [schema, uiSchema] = usePatientFormFields(useSchemaProps);
  // const [displayTabsLayout] = React.useState(true);
  const _renderSubmitButton = () => {
    if (pathname.includes('enroll') || pathname.includes('draft')) {
      return (
        <Grid display='flex' alignItems='center' justifyContent='flex-end' ml='auto'>
          <Grid item marginRight={2}>
            <Button disableRipple disableElevation size='large' variant='outlined' onClick={() => navigate('..')}>
              <Typography variant='fontSemiBold16'>Cancel</Typography>
            </Button>
          </Grid>

          <Grid item marginRight={2}>
            <LoadingButton
              size='large'
              onClick={() => {
                if (buttonRef.current) {
                  buttonRef.current.click = 'save-draft';
                }
              }}
              endIcon={<KeyboardArrowRightIcon />}
              loading={isApiCalling}
              loadingPosition='end'
              disabled={isEditTCPMedications}
              ref={buttonRef}
              type='submit'
              variant='contained'
              color='info'
              sx={{ p: '10.5px 8px' }}
              disableRipple
              disableElevation>
              <Typography variant='fontSemiBold16' color={appTheme.palette.customColor.primary} sx={{ marginRight: '10px' }}>
                Save as Draft
              </Typography>
            </LoadingButton>
          </Grid>

          <Grid item>
            <LoadingButton
              size='large'
              ref={buttonRef}
              type='submit'
              onClick={() => {
                if (buttonRef.current) {
                  buttonRef.current.click = 'save-approve';
                }
              }}
              endIcon={<KeyboardArrowRightIcon />}
              loading={isApiCalling}
              loadingPosition='end'
              variant='contained'
              disabled={isEditTCPMedications}
              color='primary'
              sx={{ p: '10.5px 8px' }}
              disableRipple
              disableElevation>
              <Typography variant='fontSemiBold16'>Submit For Approval</Typography>
            </LoadingButton>
          </Grid>
        </Grid>
      );
    }

    if (pathname.includes('approve') && showApproveButton) {
      return (
        <Box
          sx={{
            position: 'fixed',
            top: 135,
            right: 30,
            zIndex: '1300',
            borderRadius: '7px',
            padding: '15px',
            backgroundColor: '#F5F9FE',
            boxShadow: '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)',
          }}>
          <Button onClick={handleClickOpenSettingDrawer} variant='outlined' sx={{ mr: 3 }} startIcon={<SettingsIcon width='22px' height='22px' />}>
            Settings
          </Button>
          <UserPermissionPage roles={[APP_ROLES_DOCTORS]} showNoAccessMessage={false}>
            <LoadingButton
              size='large'
              endIcon={<KeyboardArrowRightIcon />}
              onClick={handleClickSubmitApprove}
              disabled={submitApproveIsLoading}
              loadingPosition='end'
              variant='contained'
              color='primary'
              sx={{ paddingInline: '30px' }}
              disableRipple
              disableElevation
              id='approve'>
              <Typography variant='fontSemiBold16'>Approve</Typography>
            </LoadingButton>
          </UserPermissionPage>
        </Box>
      );
    }

    if (pathname.includes('approve') && !showApproveButton) {
      return (
        <Grid display='flex' alignItems='center' justifyContent='flex-end' ml='auto'>
          <Grid item marginRight={2}>
            <LoadingButton
              size='large'
              endIcon={<KeyboardArrowRightIcon />}
              loadingPosition='end'
              disabled={isEditTCPMedications}
              variant='outlined'
              color='error'
              sx={{ p: '7px 15px' }}
              disableRipple
              disableElevation
              onClick={onClickShowCancelChangesConfirmationDialog}>
              <Typography variant='fontSemiBold16' sx={{ marginRight: '10px', marginLeft: '10px' }}>
                Cancel
              </Typography>
            </LoadingButton>
          </Grid>

          <Grid item marginRight={2}>
            <button
              className={isEditTCPMedications ? 'save-approve-button-disabled' : 'save-approve-button'}
              ref={buttonRef}
              disabled={isEditTCPMedications}
              onClick={() => {
                if (buttonRef.current) {
                  buttonRef.current.click = 'save-approve';
                }
              }}>
              <Typography variant='fontSemiBold16'>Save</Typography>
            </button>
          </Grid>
        </Grid>
      );
    }

    return <button type='submit'>Click me</button>;
  };

  if (loading || organizatoinFields.isLoading) {
    return <CustomLoading />;
  }

  if (partnerOrganizationId && partnerOrganizationId !== userSession?.organization?.organizationId) {
    return <NoAccessPage />;
  }

  return (
    <>
      <div style={{ paddingBottom: '30px', position: 'relative' }}>
        {openSettingDrawer && <ProfileSettingPanelSidebar openDrawer={openSettingDrawer} toggleSettingPanel={handleCloseSettingDrawer} isEnrolmentContext displayModal />}

        <div id='patient-enrolment-form-container' style={{ margin: '2%' }}>
          <Form
            // ref={formDataRef}
            schema={schema}
            uiSchema={uiSchema}
            validator={validator}
            templates={{ TitleFieldTemplate, FieldErrorTemplate }}
            formData={formData}
            liveValidate
            customValidate={customValidate}
            transformErrors={transformErrors}
            showErrorList={false}
            onError={(errors: any) => {
              console.error('errors', errors);
              if (errors.length > 0) {
                const errorProperty: string = errors[0].property as string;
                const path = errorProperty.split('.');
                const id = `root${path.join('_')}`;
                customElementScollToView(id);
              }
            }}
            onSubmit={onSubmit}
            onChange={(data: any, id: any) => {
              onChangeForm(data.formData, id);
            }}>
            <Box
              sx={{
                position: 'fixed',
                bottom: 0,
                zIndex: 100,
                right: 0,
                left: 0,
                boxShadow: '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)',
                backgroundColor: 'white',
                display: 'flex',
                alignItems: 'center',
                padding: '20px 10px',
                borderRadius: '5px',
              }}>
              {_renderSubmitButton()}
            </Box>
          </Form>
        </div>

        <ConfirmationDialog
          isOpen={showCancelChangesDialog}
          onClose={onClickHideCancelChangesConfirmationDialog}
          description='If you confirm, the changes cannot be undone'
          title='Are you sure you want to discard all changes made to patient information?'
          onClickConfirm={onClickCancelSavedChanges}
        />
      </div>
    </>
  );
};
