/** @format */

import _ from 'underscore';
import { UserSessionType } from '../types/OrganizationUsers';

export const getUserSession = () => {
  let userSession: UserSessionType = {} as UserSessionType;
  try {
    userSession = JSON.parse(localStorage.getItem('userSession') || '');
  } catch (err) {
    console.error('err', err);
  }
  return userSession;
};

export const checkUserHasPermission = (userRoles: string[]) => {
  const userSession = getUserSession();

  console.log('userSessionuserSession', userSession);

  return _.isEmpty(userRoles) || (!_.isEmpty(userSession.productRole) && !_.isEmpty(userSession.productRole?.roles) && userSession.productRole?.roles?.some((ele) => userRoles.includes(ele)));
};
