/** @format */

import * as React from 'react';
import Scrollbars from 'react-custom-scrollbars-2';

import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Grid from '@mui/material/Grid';
import AppBar from '@mui/material/AppBar';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Tab, { TabProps } from '@mui/material/Tab';

import AttachmentIcon from '@mui/icons-material/Attachment';

import Summary from '../../components/patient-profile/medical-history/Summary';
import Timelines from '../../components/patient-profile/medical-history/Timeline';
import Attachments from '../../components/patient-profile/patient-attachments/Attachments';
import CustomLightTooltip from '../../components/common/custom-tooltip/CustomLightTooltip';
import PrescribedMedications from '../../components/patient-profile/medical-history/PrescribedMedications';

interface InformationLayoutProps {
  medicalHistoryTabValue: string;
  handleTabChangeMedicalHistory: (event: React.SyntheticEvent, newValue: string) => void;
}

type CombinedTabProps = TabProps & { tooltip?: boolean };

const InformationLayout: React.FunctionComponent<InformationLayoutProps> = (props) => {
  const appTheme = useTheme();

  const _renderTabs = (tab: CombinedTabProps, currValue: string) => {
    const tabContent = (
      <Tab
        key={tab.id}
        id={tab.id}
        label={
          <Typography sx={{ textTransform: 'none' }} variant={currValue === tab.value ? 'fontSemiBold14' : 'fontReg14'} color='#5C6A90'>
            {tab.icon ? tab.icon : tab.label}
          </Typography>
        }
        value={tab.value}
      />
    );

    if (!tab.tooltip) return tabContent;

    return (
      <CustomLightTooltip title={tab.label} arrow>
        {tabContent}
      </CustomLightTooltip>
    );
  };

  const medicalHistoryTabs: CombinedTabProps[] = [
    {
      id: 'summary',
      value: 'SUMMARY',
      label: 'Summary',
      tooltip: false,
    },
    {
      id: 'timelines',
      value: 'TIMELINES',
      label: 'Timelines',
      tooltip: false,
    },
    {
      id: 'prescribed-medications',
      value: 'PRESCRIBED_MEDICATIONS',
      label: 'Prescribed Medication',
      tooltip: false,
    },
    {
      id: 'attachment',
      value: 'ATTACHMENT',
      label: 'Attachments',
      icon: <AttachmentIcon />,
      tooltip: true,
    },
  ];

  return (
    <Box sx={{ marginLeft: '5px', background: '#F8F8F8' }}>
      <AppBar
        elevation={0}
        position='static'
        sx={{
          padding: 0,
          margin: 0,
          background: appTheme.palette.customColor.tabBgColor,
          borderBottom: '.6px solid #D5D5D6',
          borderTopLeftRadius: '5px',
          borderTopRightRadius: '5px',
          '& .MuiTabs-root': {
            background: '#F8F8F8',
          },
        }}>
        <Tabs
          TabIndicatorProps={{
            sx: {
              height: '3px',
            },
          }}
          value={props.medicalHistoryTabValue}
          variant='scrollable'
          scrollButtons='auto'
          onChange={props.handleTabChangeMedicalHistory}
          sx={{ '> .MuiButtonBase-root': { color: '#3E4685 !important' }, '> .MuiTabs-scrollButtons': { display: 'inherit !important' }, backgroundColor: 'white' }}
          aria-label='full width tabs'>
          {medicalHistoryTabs.map((e) => _renderTabs(e, props.medicalHistoryTabValue))}
        </Tabs>
      </AppBar>

      <Grid item xs={12} sx={{ padding: '10px 0 0 5px', height: '80vh' }}>
        <Scrollbars autoHide>
          {props.medicalHistoryTabValue === 'SUMMARY' && <Summary />}
          {props.medicalHistoryTabValue === 'TIMELINES' && <Timelines />}
          {props.medicalHistoryTabValue === 'PRESCRIBED_MEDICATIONS' && <PrescribedMedications />}
          {props.medicalHistoryTabValue === 'ATTACHMENT' && <Attachments />}
        </Scrollbars>
      </Grid>
    </Box>
  );
};

export default InformationLayout;
