/** @format */

import * as React from 'react';
import { DateTime } from 'luxon';
import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import CssBaseline from '@mui/material/CssBaseline';

import ActionLayout from './ActionLayout';
import InformationLayout from './InformationLayout';
import PDFViewer from '../../components/common/PDFViewer';
import CustomLoading from '../../components/common/custom-loading/CustomLoading';
import { LabReportModel } from '../../components/patient-report-modal/LabReportModel';
import PatientBasicDetails from '../../components/patient-profile/PatientBasicDetails';
import SymptomsReportModal from '../../components/patient-report-modal/SymptomsReportModal';
import MedicationAdherenceStatusModal from '../../components/patient-report-modal/MedicationAdherenceStatusModal';
import { ActionLayoutContainer, InformationLayoutDrawerContainer } from './Styled';

import { PatientProfileContext } from './PatientProfileContext';

import { BasicDetailsType } from '../../types/Patients.types';
import { PatientVitalsReadingType } from '../../types/PatientVitals.types';
import { PrescriptionGeneratorTabsType } from '../../types/PrescriptionsGenerator.types';

const Selectstyle = {
  control: (baseStyles: any, state: any) => ({
    ...baseStyles,
    padding: '4px',
    border: '1px solid #9BB4CC',
    width: 'max-content',
    minWidth: '100%',
  }),
  menuList: (base: any) => ({
    ...base,
    maxWidth: '250px',
  }),
  menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
};

interface PatientProfileProps {}

const PatientProfile: React.FunctionComponent<PatientProfileProps> = () => {
  const navigate = useNavigate();

  const {
    isAPILoading,
    notesIsLoading,
    patientAlreadyPrescribedMedications,

    patientInfo,

    isPatientSymptomsUpdatingApiCalling,
    handleSubmitPatientSymptoms,

    isMedicalAdherenceUpdatingApiCalling,
    handleSubmitMedicalAdherence,

    isLabReportUpdatingApiCalling,
    selectedPdfUrl,
    handleSubmitLabReports,
    handleSubmitPatientAttachment,
    handleSubmitPatientAttachments,
    setSelectedPdfUrl,
    isPatientAttachmentUploading,

    openCareGiverDetails,
    profileTab,
    setProfileTab,
  } = React.useContext(PatientProfileContext);

  const [medicalHistoryTabValue, setMedicalHistoryTabValue] = React.useState<string>('SUMMARY');

  const [prescriptionTabValue, setPrescriptionTabValue] = React.useState<PrescriptionGeneratorTabsType>('OBSERVATION_ASSESSMENTS');

  const [isOpenLabReportModal, setIsOpenLabReportModal] = React.useState<boolean>(false);
  const [isOpenSymptomsModal, setIsOpenSymptomsModal] = React.useState<boolean>(false);
  const [isOpenMedicationAdherenceModal, setIsOpenMedicationAdherenceModal] = React.useState<boolean>(false);

  const [screenWidth, setScreenWidth] = React.useState<number>(window.innerWidth);

  React.useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.screen.width);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const [appointmentFilters, setAppointmentFilters] = React.useState<Partial<{ patientSourceId: string; startDate: string; endDate: string; patientDetails: any }>>({
    startDate: DateTime.local().toISO()!,
    endDate: DateTime.local().plus({ hour: 1 }).toISO()!,
    patientDetails: {},
  });

  const toggleMedicationAdherenceModal = () => {
    setIsOpenMedicationAdherenceModal((prev) => !prev);
  };

  const toggleSymptomsModal = () => {
    setIsOpenSymptomsModal((prev) => !prev);
  };

  const handleChangeAppointmentFilters = (payload: any) => {
    setAppointmentFilters((prev) => ({ ...prev, ...payload }));
  };

  const toggleLabReportsModal = () => {
    setIsOpenLabReportModal((prev) => !prev);
  };

  const handleTabChangeMedicalHistory = (event: React.SyntheticEvent, newValue: string) => {
    setMedicalHistoryTabValue(newValue);
  };

  const handleTabChangeProfileTab = (event: React.SyntheticEvent, newValue: string) => {
    setProfileTab(newValue);
  };

  const handleClosePdfReportPreview = () => {
    setSelectedPdfUrl(null); // Close the PDF viewer
  };

  const handleTabChangePrescriptionGenerator = (event: React.SyntheticEvent, newValue: PrescriptionGeneratorTabsType) => {
    if (newValue === 'TREATMENT_ADVICE') {
      setMedicalHistoryTabValue('PRESCRIBED_MEDICATIONS');
    }

    setPrescriptionTabValue(newValue);
  };

  const handleClickLabReports = async (data: PatientVitalsReadingType[]) => {
    await handleSubmitLabReports(data);
    toggleLabReportsModal();
  };

  const handleSubmitMedicalAdherenceModal = async (data: any) => {
    await handleSubmitMedicalAdherence(data);
    toggleMedicationAdherenceModal();
  };

  const handleSubmitPatientSymptomsModal = async (data: any) => {
    await handleSubmitPatientSymptoms(data);
    toggleSymptomsModal();
  };

  React.useEffect(() => {
    if (patientAlreadyPrescribedMedications.some((item) => 'addToNewPrescriptions' in item || 'stopInNewPrescriptions' in item)) {
      setPrescriptionTabValue('TREATMENT_ADVICE');
    }
  }, [patientAlreadyPrescribedMedications]);

  const actionLayout = React.useMemo(() => {
    return (
      <ActionLayout
        openCareGiverDetails={openCareGiverDetails}
        profileTab={profileTab}
        setProfileTab={setProfileTab}
        handleTabChangeProfileTab={handleTabChangeProfileTab}
        toggleSymptomsModal={toggleSymptomsModal}
        toggleMedicationAdherenceModal={toggleMedicationAdherenceModal}
        toggleLabReportsModal={toggleLabReportsModal}
        prescriptionTabValue={prescriptionTabValue}
        handleTabChangePrescriptionGenerator={handleTabChangePrescriptionGenerator}
        notesIsLoading={notesIsLoading}
        appointmentFilters={appointmentFilters}
        handleChangeAppointmentFilters={handleChangeAppointmentFilters}
      />
    );
  }, [appointmentFilters, notesIsLoading, prescriptionTabValue, profileTab]);

  if (isAPILoading) {
    return <CustomLoading />;
  }

  return (
    <>
      <Grid id='patient-profile-container' item xs={12} container>
        <CssBaseline />
        <ActionLayoutContainer isLowerScreenWidth={screenWidth < 1300}>
          <>
            <Box component='div' sx={{ padding: '10px' }}>
              <Breadcrumbs aria-label='breadcrumb' sx={{ marginBottom: '3px' }}>
                <Link underline='hover' color='inherit' onClick={() => navigate(-1)}>
                  <Typography color='text.primary' variant='fontReg14' sx={{ cursor: 'pointer', color: '#5C6A90' }}>
                    Patient list /
                  </Typography>
                </Link>
              </Breadcrumbs>
              <PatientBasicDetails />
            </Box>

            {actionLayout}

            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              {selectedPdfUrl && <PDFViewer isOpen={Boolean(selectedPdfUrl)} handleClose={handleClosePdfReportPreview} url={selectedPdfUrl} />}
            </Box>
          </>
        </ActionLayoutContainer>

        <InformationLayoutDrawerContainer isLowerScreenWidth={screenWidth < 1300}>
          <InformationLayout medicalHistoryTabValue={medicalHistoryTabValue} handleTabChangeMedicalHistory={handleTabChangeMedicalHistory} />
        </InformationLayoutDrawerContainer>
      </Grid>

      {isOpenSymptomsModal && (
        <SymptomsReportModal
          isOpen={isOpenSymptomsModal}
          isUpdatingAPICalling={isPatientSymptomsUpdatingApiCalling}
          onClose={toggleSymptomsModal}
          handleSubmit={handleSubmitPatientSymptomsModal}
          patientBasicDetails={patientInfo.basicDetails as BasicDetailsType}
        />
      )}

      {isOpenMedicationAdherenceModal && (
        <MedicationAdherenceStatusModal
          isOpen={isOpenMedicationAdherenceModal}
          isUpdatingAPICalling={isMedicalAdherenceUpdatingApiCalling}
          onClose={toggleMedicationAdherenceModal}
          handleSubmit={handleSubmitMedicalAdherenceModal}
          patientBasicDetails={patientInfo.basicDetails as BasicDetailsType}
        />
      )}

      {isOpenLabReportModal && (
        <LabReportModel
          Selectstyle={Selectstyle}
          isPatientAttachmentUploading={isPatientAttachmentUploading}
          isOpen={isOpenLabReportModal}
          isUpdatingAPICalling={isLabReportUpdatingApiCalling}
          handleSubmitPatientAttachment={handleSubmitPatientAttachment}
          handleSubmitPatientAttachments={handleSubmitPatientAttachments}
          onClose={toggleLabReportsModal}
          setSelectedPdfUrl={setSelectedPdfUrl}
          patientBasicDetails={patientInfo.basicDetails!}
          handleSubmit={handleClickLabReports}
        />
      )}
    </>
  );
};

export default PatientProfile;
