/** @format */

import React from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';

export const LightTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    color: '#646464',
    fontWeight: '600',
    boxShadow: theme.shadows[1],
    fontSize: theme.typography.pxToRem(12),
    backgroundColor: theme.palette.common.white,
  },
}));
