/** @format */

import * as _ from 'underscore';

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BasicDetailsType } from '../../types/Patients.types';
import { getOrgUsers } from '../../service/notes';
import { DropdownOptionType } from '../../types/CustomForm.types';
import { getUserRegion } from '../../service/user-regsion';

export interface AlertInterface {
  id: string;
  value: string;
}

export interface UserRegionState {
  status: string;
  country: string;
  countryCode: string;
  region: string;
  regionName: string;
  city: string;
  zip: string;
  lat: string;
  lon: string;
  timezone: string;
  isp: string;
  org: string;
  as: string;
  query: string;

  showVideoCall: boolean;
  onGoingVideoCall?: {
    isOnCall: boolean;
    patientSourceId: string;
  };
  patientDetials: BasicDetailsType | undefined;
  isLoading: boolean;
  orgUsers: DropdownOptionType[];
}

export const initialState: UserRegionState = {
  countryCode: 'IN',
  showVideoCall: false,
} as UserRegionState;

export const FetchDoctors = createAsyncThunk('userRegion/doctors', async () => {
  try {
    // API call.
    const res1 = getOrgUsers('DOCTORS');
    const res2 = getUserRegion();

    const res = await Promise.allSettled([res1, res2]);
    return res.map((e: any) => e.value);
  } catch (error) {
    console.error(error);
  }
});

export const userRegionReducer = createSlice({
  name: 'userRegion',
  initialState,
  reducers: {
    setUserRegion: (state, { payload }: PayloadAction<UserRegionState>) => {
      state = { ...payload, ...state };
      return state;
    },

    handleClickToggleVideoCall: (state, { payload }: PayloadAction<BasicDetailsType | undefined>) => {
      state.showVideoCall = !state.showVideoCall;

      state.onGoingVideoCall = {
        isOnCall: state.showVideoCall,
        patientSourceId: payload?.patientSourceId!,
      };

      state.patientDetials = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(FetchDoctors.fulfilled, (state: UserRegionState, action: any) => {
      if (action.payload && action.payload?.length > 0) {
        state.orgUsers = action.payload?.[0]?.map((e: any) => {
          return { label: e.user.name.toUpperCase(), value: e.user.userId };
        });

        if (action.payload?.length > 1 && !_.isEmpty(action.payload[1])) {
          state.status = action.payload[1].status;
          state.country = action.payload[1].country;
          state.countryCode = action.payload[1].countryCode;
          state.region = action.payload[1].region;
          state.regionName = action.payload[1].regionName;
          state.city = action.payload[1].city;
          state.zip = action.payload[1].zip;
          state.lat = action.payload[1].lat;
          state.lon = action.payload[1].lon;
          state.timezone = action.payload[1].timezone;
          state.isp = action.payload[1].isp;
          state.org = action.payload[1].org;
          state.as = action.payload[1].as;
          state.query = action.payload[1].query;
        } else {
          state.country = 'India';
          state.countryCode = 'IN';
        }

        state.isLoading = false;
      }
    });

    builder.addCase(FetchDoctors.rejected, (state: UserRegionState) => {
      state.isLoading = false;
    });

    builder.addCase(FetchDoctors.pending, (state: UserRegionState) => {
      state.isLoading = true;
    });
  },
});

export const { setUserRegion, handleClickToggleVideoCall } = userRegionReducer.actions;
