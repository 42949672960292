/** @format */

import * as React from 'react';

import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import { BasicDetailsType } from '../../types/Patients.types';
import { PatientVitalsReadingType } from '../../types/PatientVitals.types';
import IconButton from '@mui/material/IconButton';
import Select from 'react-select';
import { Box, Button, Chip, CircularProgress, Dialog, DialogActions, Divider, FormControl, FormHelperText, Grid, Input, SelectChangeEvent, Slide, Typography } from '@mui/material';
import '../patient-profile/patient-attachments/AddReport.scss';
import { TransitionProps } from '@mui/material/transitions';
import AddIcon from '@mui/icons-material/Add';
import DateRangePicker from '../common/date-range-picker/DateRangePicker';
import { DateTime } from 'luxon';
import './LabReports.scss';
import { LAB_REPORT_TEST } from '../common/charts/rca-resources/constants';
import _ from 'underscore';
import { V2PatientAttachmentPayloadAttachmnetType, V2PatientAttachmentReportType, V2PatientAttachmentReportsPayloadReportType } from '../../types/PatientAttachments.types';
// import { PatientProfileContext } from '../../pages/patient-profile/PatientProfileContext';
import LabReportItem from '../patient-profile/patient-attachments/LabReportItem';
import ZoomDeleteIcon from '../patient-profile/patient-attachments/CloseIcon';
import { SelectedPdfUrlType } from '../../pages/patient-profile/PatientProfileContext';

interface LabReportsProps {
  isOpen: boolean;
  isUpdatingAPICalling: boolean;

  onClose: () => void;
  patientBasicDetails: BasicDetailsType;
  isPatientAttachmentUploading: boolean;
  setSelectedPdfUrl: (payload: SelectedPdfUrlType | null) => void;
  handleSubmit: (vitals: PatientVitalsReadingType[]) => void;
  handleSubmitPatientAttachment: (payload: V2PatientAttachmentReportType) => void;
  handleSubmitPatientAttachments: (fileUpload: any, type: string) => void;
  hideDialogBehaviour?: boolean;
  Selectstyle?: any;
  disabled?: boolean;
}
const Transition = React.forwardRef(function Transition (
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction='down' ref={ref} {...props} />;
});

export const LabReportModel: React.FunctionComponent<LabReportsProps> = (props) => {
  // if (pathname.includes('monitor-patients')) {}
  // const { setSelectedPdfUrl } = React.useContext(PatientProfileContext);

  const [reportsDate, setReportsDate] = React.useState<string>(DateTime.now().toISO());
  const [reportNameError, setReportNameError] = React.useState<string>('');
  const [reportName, setReportName] = React.useState<string>('');
  const [summaryError, setSummaryError] = React.useState<string>('');
  const [reportSummary, setReportSummary] = React.useState<string>('');
  const [addTestReactSelect, setAddTestReactSelect] = React.useState<{ label: string; value: string }[] | null>(null);
  const [selectedAttachmentReportTest, setSelectedAttachmentReportTest] = React.useState<string | null>(null);
  const [attachmentReportTests, setAttachmentReportTests] = React.useState<V2PatientAttachmentReportsPayloadReportType[]>([]);

  const [attachmnetReportFiles, setAttachmentReportFiles] = React.useState<V2PatientAttachmentPayloadAttachmnetType[]>([]);

  let name = '';
  if (props.patientBasicDetails?.firstName && props.patientBasicDetails?.lastName) {
    name = props.patientBasicDetails?.firstName + ' ' + props.patientBasicDetails?.lastName;
  }
  const handleClickActiveChip = (chipName: string) => {
    setSelectedAttachmentReportTest(chipName);
  };

  const handleCloseReportActive = () => {
    setSelectedAttachmentReportTest(null);
  };
  const handleDeleteSelectedValues = (chipName: string) => {
    const updatedSelectedValues = attachmentReportTests.filter((values: any) => values.name !== chipName);
    setAttachmentReportTests(updatedSelectedValues);
  };

  const handleChangeDate = (date: string) => {
    const updatedDate = DateTime.fromJSDate(new Date(date)).toISO() || '';
    setReportsDate(updatedDate);
  };

  const handleChangeInputReports = (value: any, dataIndex: number, variable: string) => {
    setAttachmentReportTests((prev: V2PatientAttachmentReportsPayloadReportType[]) => {
      return prev.map((item) => {
        if (item.name === selectedAttachmentReportTest) {
          const updatedItem = item.tests?.map((ele, index) => {
            if (index === dataIndex) {
              return { ...ele, [variable]: value };
            }

            return ele;
          });

          return {
            ...item,
            tests: updatedItem,
          };
        }

        return item;
      });
    });
  };

  const handleSubmit = async () => {
    if (_.isEmpty(reportName)) {
      setReportNameError('Report Name Cannot be Empty');
      return;
    } else {
      setReportNameError('');
    }

    {
      const transformedData = attachmnetReportFiles.map((item) => ({
        fileName: item.file.name,
        fileId: item.attachmentId,
        type: item.file.type,
      }));

      const payload: V2PatientAttachmentReportType = {
        name: reportName,
        summary: reportSummary,
        reports: attachmentReportTests,
        attachments: transformedData,

        payload: {},

        type: 'REPORTS',
        reportDate: reportsDate,
      };
      await props.handleSubmitPatientAttachment(payload);
    }

    props.onClose();
  };

  const handleChangeAddTest = (event: SelectChangeEvent | any) => {
    if (event !== null) {
      let chipName: string = event.value;

      if (event.key === 'Enter') {
        event.preventDefault();
        chipName = event.target.value;
      }

      if (_.isEmpty(chipName)) {
        return;
      }

      // setting active chip
      handleClickActiveChip(chipName);

      setAttachmentReportTests((prev: any) => {
        const duplicateChip = prev.find((ele: V2PatientAttachmentReportsPayloadReportType) => ele.name === chipName);

        if (duplicateChip) {
          return prev;
        }

        const testItems = LAB_REPORT_TEST.find((item) => item.profileName === chipName);

        const tests = (testItems?.tests || []).map((item: string, index: number) => ({ id: index, name: item, value: '' }));

        return [...prev, { name: chipName, tests }];
      });
    } else {
      // Setting null value for react-select as data is cleared
      setAddTestReactSelect(null);
    }
  };

  const handleAddCipTableColumn = () => {
    setAttachmentReportTests((prev: any) => {
      const updatedChip = prev.find((ele: V2PatientAttachmentReportsPayloadReportType) => ele.name === selectedAttachmentReportTest);

      if (updatedChip) {
        return prev.map((chip: V2PatientAttachmentReportsPayloadReportType) => {
          if (chip.name === selectedAttachmentReportTest) {
            return {
              name: chip.name,
              tests: [...chip.tests, { id: chip.tests.length + 1, name: '', value: '' }],
            };
          }

          return chip;
        });
      }

      return prev;
    });
  };

  const handleFileInputChange = async (event: any) => {
    const file = event.target.files[0];
    if (file && (file.type === 'application/pdf' || file.type === 'image/jpeg')) {
      if (attachmnetReportFiles.length < 3) {
        const attachmentId: any = await props.handleSubmitPatientAttachments(file, 'REPORTS');
        setAttachmentReportFiles((prev: any) => [...prev, { attachmentId: attachmentId.attachmentId, file }]);
      }
    }
  };

  const handleOpenPdf = (file: any) => {
    if (file.fileUrl) {
      props.setSelectedPdfUrl(file?.fileUrl);
    } else {
      const pdfDataUrl = URL.createObjectURL(file.file);
      props.setSelectedPdfUrl({ pdfUrl: pdfDataUrl, fileType: file.file.type });
    }
  };

  const handleDeleteSelectedPdf = (e: any) => {
    const updatedPdfList = attachmnetReportFiles.filter((file: any) => file !== e);

    setAttachmentReportFiles(updatedPdfList);
    props.setSelectedPdfUrl(null);
  };

  const handleReportValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    if (value.length > 100) {
      setReportNameError('Report name must be less than 100 characters');
    } else {
      setReportNameError('');
    }
    setReportName(value);
  };

  const handleReportSummary = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (value.length > 300) {
      setSummaryError('Summary must be less than 300 characters');
    } else {
      setSummaryError('');
    }
    setReportSummary(value);
  };
  const disabledNtn = _.isEmpty(reportName) || !_.isEmpty(reportNameError) || !_.isEmpty(summaryError) || _.isEmpty(attachmentReportTests);

  const _renderDialogContext = () => {
    return (
      <>
        <DialogTitle className='title'>
          <span className='heading'>Lab Report ({name.length !== 0 ? `${name} / ${props.patientBasicDetails.patientId}` : `${props.patientBasicDetails.patientId}`})</span>

          <IconButton size='small' color='info' className='button' onClick={props.onClose}>
            <CloseIcon color='primary' />
          </IconButton>
        </DialogTitle>

        <DialogContent sx={{ marginTop: '25px', minHeight: '250px' }}>
          <FormControl fullWidth>
            <Input
              placeholder='Report'
              disableUnderline={true}
              error={!_.isEmpty(reportNameError)}
              onChange={handleReportValue}
              value={reportName}
              sx={{
                border: '1px solid #9BB4CC',
                borderRadius: '5px',
                padding: '10px 10px',
                '.MuiInput-input': {
                  padding: 0,
                },
                '::placeholder': {
                  color: '#3E4685',
                },
              }}
            />
            {!_.isEmpty(reportNameError) && <FormHelperText error>{reportNameError}</FormHelperText>}

            <Input
              placeholder='Summary'
              disableUnderline={true}
              onChange={handleReportSummary}
              value={reportSummary}
              sx={{
                border: '1px solid #9BB4CC',
                borderRadius: '5px',
                padding: '10px 10px',
                '.MuiInput-input': {
                  padding: 0,
                },
                '::placeholder': {
                  color: '#3E4685',
                },
                marginTop: '25px',
              }}
            />
            {!_.isEmpty(summaryError) && <FormHelperText error>{summaryError}</FormHelperText>}
          </FormControl>

          <Grid container justifyContent={'space-between'} alignItems={'center'} sx={{ marginTop: '25px' }}>
            <Grid item xs={5.6}>
              <Select
                styles={props.Selectstyle}
                placeholder={
                  <div style={{ display: 'flex', alignItems: 'center', color: 'grey' }}>
                    <AddIcon /> <Typography color='#33425B'>Add Tests</Typography>
                  </div>
                }
                menuPortalTarget={document.body}
                maxMenuHeight={120}
                value={addTestReactSelect}
                name='Add Tests'
                className='react-select-container'
                classNamePrefix='Add Tests'
                isClearable
                isOptionDisabled={(option: any) => option.disabled}
                isSearchable
                options={LAB_REPORT_TEST.map((item) => ({
                  label: item.profileName,
                  value: item.profileName,
                  disabled: !_.isEmpty(attachmentReportTests?.find((ele) => ele.name === item.profileName)),
                }))}
                onChange={handleChangeAddTest}
                onKeyDown={handleChangeAddTest}
              />
            </Grid>
            <Divider orientation='vertical' style={{ width: 2, height: '35px' }} variant='middle' flexItem />

            <Grid item xs={5.6}>
              <Box
                sx={{
                  border: '1px solid #9BB4CC',
                  borderRadius: '5px',

                  padding: '10px 10px',
                }}>
                <label htmlFor='file-upload' style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                  <AddIcon sx={{ color: 'grey', marginRight: '5px' }} />
                  <Typography color='#33425B'>Add Files</Typography>
                </label>
                <input id='file-upload' type='file' accept='.pdf, .jpeg' style={{ display: 'none' }} onChange={handleFileInputChange} />
              </Box>
            </Grid>
          </Grid>

          <Grid container sx={{ mt: 1 }}>
            <Grid item xs={5.5} container gap={0.5}>
              {attachmentReportTests.map((ele: any, id: number) => (
                <LabReportItem
                  key={id}
                  labTestItems={ele}
                  isEdit={true}
                  isOpen={ele.name === selectedAttachmentReportTest}
                  handlClickReportChip={() => handleClickActiveChip(ele.name)}
                  handleCloseReportChip={handleCloseReportActive}
                  handleDeleteSelectedValues={handleDeleteSelectedValues}
                  handleOnChange={handleChangeInputReports}
                  handleAddTableColumn={handleAddCipTableColumn}
                />
              ))}
            </Grid>

            <Grid item xs={0.9}></Grid>

            <Grid item xs={5.6}>
              {attachmnetReportFiles?.map((e: any, index: number) => (
                <Chip
                  size='medium'
                  label={props.isPatientAttachmentUploading && !attachmnetReportFiles[index] ? <CircularProgress size={10} /> : e?.file?.name || e?.fileName}
                  key={index}
                  deleteIcon={<ZoomDeleteIcon />}
                  onDelete={() => {
                    handleDeleteSelectedPdf(e);
                  }}
                  onClick={() => handleOpenPdf(e)}
                />
              ))}
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions sx={{ padding: '0px 30px', paddingBottom: '10px' }}>
          <Grid container justifyContent={'space-between'} mt={2.5}>
            <Grid item xs={4}>
              <DateRangePicker
                maxDate={new Date()}
                handleChangeStartDate={console.info}
                handleChangeEndDate={console.info}
                startDate={reportsDate}
                endDate={reportsDate}
                showCalendarIcon={false}
                showIcon={false}
                selectsRange={false}
                dateFormat={'MMMM d, yyyy'}
                preSelectedDate={reportsDate}
                handleChangeDate={handleChangeDate}
              />
            </Grid>
            <Grid item container xs={7} gap={2} justifyContent={'right'}>
              <Button disabled={disabledNtn} variant='contained' onClick={handleSubmit}>
                Save
              </Button>

              <Button variant='outlined' onClick={props.onClose}>
                Cancel
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </>
    );
  };

  if (props.hideDialogBehaviour) {
    return _renderDialogContext();
  }

  return (
    <Dialog
      className='lab-reports-main'
      open={props.isOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={props.onClose}
      // sx={{ '&>div': { width: '500px' } }}
      maxWidth='sm'
      fullWidth={true}>
      {_renderDialogContext()}
    </Dialog>
  );
};
