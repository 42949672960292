/** @format */

import * as React from 'react';
import * as _ from 'underscore';
import axios, { CancelTokenSource } from 'axios';

import Typography from '@mui/material/Typography';

import { CustomTableSelectCell } from './CustomTableComponent';
import { ConfirmationDialog } from '../common/confirmation-dialog/ConfirmationDialog';

import type { FormatOptionLabelMeta } from 'react-select';
import { PatientInfoType } from '../../types/Patients.types';
import type { FilterOptionOption } from 'react-select/dist/declarations/src/filters';
import { SystemConfigMedicationWithBrandName, SystemConfigMedicationWithBrandNameOptions } from '../../types/SystemMedications.types';
import { PrescriptionsGeneratorMedicationsCommonItemType } from '../../types/PrescriptionsGenerator.types';
import { getSystemConfigMedicationsWithBrandNames } from '../../service/config-medications';

interface PrescriptionMedicationSelectComponentProps {
  rowIndex: number;
  data: PrescriptionsGeneratorMedicationsCommonItemType;
  error?: PrescriptionsGeneratorMedicationsCommonItemType;

  patientInfo?: PatientInfoType;

  patientPrescriptionMedications?: PrescriptionsGeneratorMedicationsCommonItemType[];

  handleChangePatientPrescriptionMedications: (value: any, variable: keyof PrescriptionsGeneratorMedicationsCommonItemType, rowIndex: number) => void;

  isDisabled?: boolean;
}

const getDosageUnit = (medications: SystemConfigMedicationWithBrandNameOptions) => {
  let unit = '';

  if (medications.medicationType?.toLowerCase().startsWith('cap')) {
    unit = 'caps';
  } else if (medications.medicationType?.toLowerCase().startsWith('tab')) {
    unit = 'tabs';
  } else if (medications.medicationType?.toLowerCase().startsWith('susp') || medications.medicationType?.toLowerCase().startsWith('syr')) {
    unit = 'ml';
  }

  return unit;
};

const PrescriptionMedicationSelectComponent: React.FunctionComponent<PrescriptionMedicationSelectComponentProps> = (props) => {
  const debonceSearchMedicationRef: any = React.useRef<any>(null);
  const axiosSearchMedicationRef: { current: CancelTokenSource } = React.useRef<any>(null);

  const brandNameSearchLimitRef = React.useRef<number>(700);
  const brandNameSearchOffsetRef = React.useRef<number>(0);

  const brandNameSearchTermRef = React.useRef<string>();
  const brandNameSearchTotalCountRef = React.useRef<number>(0);

  const [, setBrandNameLoading] = React.useState(false);

  const [brandNameCollection, setBrandNameCollection] = React.useState<SystemConfigMedicationWithBrandNameOptions[]>([]);

  const [isOpenValidationModal, setIsOpenValidationModal] = React.useState<boolean>(false);
  const [validationModalData, setValidationModalData] = React.useState<{ rowIndex: number; value: SystemConfigMedicationWithBrandNameOptions } | null>(null);

  const searchConfigMedicationWithBrandName = async (searchTerm: any): Promise<SystemConfigMedicationWithBrandNameOptions[]> => {
    setBrandNameLoading(true);
    if (axiosSearchMedicationRef.current && axiosSearchMedicationRef.current?.token) {
      axiosSearchMedicationRef.current?.cancel();
    }

    axiosSearchMedicationRef.current = axios.CancelToken.source();

    let medicationsBrandName: SystemConfigMedicationWithBrandNameOptions[] = [];

    try {
      const data = await getSystemConfigMedicationsWithBrandNames(
        brandNameSearchLimitRef.current,
        brandNameSearchOffsetRef.current,
        searchTerm,
        searchTerm,
        undefined,
        axiosSearchMedicationRef.current?.token,
      );
      const medications = data.rows || [];
      brandNameSearchTotalCountRef.current = data.count;
      medicationsBrandName = medications.map((item: SystemConfigMedicationWithBrandName) => {
        const isMedicationAdded = props.patientPrescriptionMedications?.find((ele) => ele.medicationName === item.medicationName);

        return { label: item.brandName, value: item.brandName, ...item, disabled: !_.isEmpty(isMedicationAdded) && item.medicationName !== props.data?.medicationName };
      });
      medicationsBrandName = medicationsBrandName.filter((item) => customFilter({ data: item } as FilterOptionOption<SystemConfigMedicationWithBrandNameOptions>, searchTerm));

      setBrandNameCollection((prev) => {
        prev = prev.concat(medicationsBrandName);
        prev = _.uniq(prev, (item) => item.brandName);
        prev = prev.filter((item) => customFilter({ data: item } as FilterOptionOption<SystemConfigMedicationWithBrandNameOptions>, searchTerm));
        return prev;
      });
    } catch (err) {
      console.error(err);
    }
    setBrandNameLoading(false);
    return medicationsBrandName;
  };

  const handleChangeMedicationBrandName = (value: SystemConfigMedicationWithBrandNameOptions | any) => {
    let showCondition = false;

    if (value?.validationEnabled) {
      showCondition = _.isEmpty(value?.validationConditions);
      if (!_.isEmpty(value?.validationConditions) && value?.validationConditions === 'HFrEF' && props.patientInfo?.medicalHistory?.heartFailureMedicalHistory.hfType === 'HFrEF') {
        showCondition = true;
      }

      if (!_.isEmpty(value?.validationConditions) && value?.validationConditions === 'HFpEF' && props.patientInfo?.medicalHistory?.heartFailureMedicalHistory.hfType === 'HFpEF') {
        showCondition = true;
      }

      if (!_.isEmpty(value?.validationConditions) && value?.validationConditions === 'Not AFIB' && !props.patientInfo?.medicalHistory?.cardiovascularDiseases?.includes('ATRIAL_FIBRILLATION')) {
        showCondition = true;
      }

      if (!_.isEmpty(value?.validationConditions) && value?.validationConditions === 'AFIB' && props.patientInfo?.medicalHistory?.cardiovascularDiseases?.includes('ATRIAL_FIBRILLATION')) {
        showCondition = true;
      }
    }

    if (showCondition) {
      setIsOpenValidationModal(true);
      setValidationModalData({ value, rowIndex: props.rowIndex });
      props.handleChangePatientPrescriptionMedications({}, 'medicationBrandNameObject', props.rowIndex);
    } else {
      let medicationName = value?.medicationName;

      if (value?.__isNew__) {
        medicationName = value?.value;
      }

      props.handleChangePatientPrescriptionMedications(value, 'medicationBrandNameObject', props.rowIndex);
      props.handleChangePatientPrescriptionMedications(value?.value, 'brandName', props.rowIndex);
      props.handleChangePatientPrescriptionMedications(medicationName, 'medicationName', props.rowIndex);
      props.handleChangePatientPrescriptionMedications(value?.medicationNameDisplayName, 'medicationNameDisplayName', props.rowIndex);
      props.handleChangePatientPrescriptionMedications(value?.saltComposition, 'saltComposition', props.rowIndex);
      props.handleChangePatientPrescriptionMedications(value?.dosage, 'dosage', props.rowIndex);
      props.handleChangePatientPrescriptionMedications(getDosageUnit(value), 'dosageUnit', props.rowIndex);
      props.handleChangePatientPrescriptionMedications(value?.__isNew__, 'isCustomMedications', props.rowIndex);
    }
  };

  const handleClickConfirmValidation = () => {
    if (validationModalData) {
      let medicationName = validationModalData?.value?.medicationName;

      if (validationModalData?.value?.__isNew__) {
        medicationName = validationModalData?.value?.value;
      }

      props.handleChangePatientPrescriptionMedications(validationModalData?.value, 'medicationBrandNameObject', validationModalData?.rowIndex);
      props.handleChangePatientPrescriptionMedications(validationModalData?.value?.value, 'brandName', validationModalData?.rowIndex);
      props.handleChangePatientPrescriptionMedications(medicationName, 'medicationName', validationModalData?.rowIndex);
      props.handleChangePatientPrescriptionMedications(validationModalData?.value?.dosage, 'dosage', validationModalData?.rowIndex);
      props.handleChangePatientPrescriptionMedications(validationModalData?.value?.medicationNameDisplayName, 'medicationNameDisplayName', validationModalData?.rowIndex);
      props.handleChangePatientPrescriptionMedications(validationModalData?.value?.saltComposition, 'saltComposition', validationModalData?.rowIndex);
      props.handleChangePatientPrescriptionMedications(getDosageUnit(validationModalData?.value), 'dosageUnit', validationModalData?.rowIndex);
      props.handleChangePatientPrescriptionMedications(validationModalData?.value?.__isNew__, 'isCustomMedications', validationModalData?.rowIndex);
    }
    handleClickCloseConfirmationValidation();
  };

  const handleClickCloseConfirmationValidation = () => {
    setIsOpenValidationModal(false);
    setValidationModalData(null);
  };

  const formatOptionLabel = (data: any, _: FormatOptionLabelMeta<any>) => {
    return (
      <Typography component='div'>
        <Typography component='div' variant='fontSemiBold14'>
          {data.label}
        </Typography>
        <Typography component='div' variant='fontReg14'>
          {data.medicationNameDisplayName || data.medicationName}
        </Typography>
      </Typography>
    );
  };

  const customFilter = (option: FilterOptionOption<any>, inputValue: string) => {
    if (
      option.data?.label.includes(inputValue) ||
      option.data?.brandName?.toLowerCase().includes(inputValue.toLowerCase()) ||
      option.data?.medicationName?.toLowerCase().includes(inputValue.toLowerCase())
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <CustomTableSelectCell
        isDisabled={props.isDisabled}
        isError={!_.isEmpty(props?.error?.brandName) || !_.isEmpty(props?.error?.medicationName) || !_.isEmpty(props?.error?.dosage)}
        value={props.data?.medicationBrandNameObject}
        menuPortalTarget={document.body}
        isCreateableAsyncSelect
        // components={{ MenuList }}
        isOptionDisabled={(option: any) => option.disabled}
        defaultOptions={brandNameCollection}
        loadOptions={(inputValue: string, cb) => {
          brandNameSearchTermRef.current = inputValue;

          if (debonceSearchMedicationRef.current) {
            clearTimeout(debonceSearchMedicationRef.current);
          }

          debonceSearchMedicationRef.current = setTimeout(async () => {
            const data = await searchConfigMedicationWithBrandName(inputValue);
            cb(data);
          }, 500);
        }}
        filterOption={customFilter}
        formatOptionLabel={formatOptionLabel}
        onChange={handleChangeMedicationBrandName}
      />

      {isOpenValidationModal && (
        <ConfirmationDialog
          description={validationModalData?.value?.validationMessage!}
          title={`Are you sure you want to add "${validationModalData?.value?.brandName}" Medications?`}
          onClickConfirm={handleClickConfirmValidation}
          isOpen={isOpenValidationModal}
          onClose={handleClickCloseConfirmationValidation}
        />
      )}
    </>
  );
};

export default PrescriptionMedicationSelectComponent;
