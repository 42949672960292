/** @format */

import * as React from 'react';
import * as _ from 'underscore';

import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

import DeleteIcon from '@mui/icons-material/Delete';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import type { CustomTableColumnType } from '../../types/CustomTable.types';
import type { PrescriptionsGeneratorMedicationsCommonItemType } from '../../types/PrescriptionsGenerator.types';

import { PrescriptionGeneratorContext } from './PrescriptionGeneratorContext';

import CustomTable from '../common/custom-table/CustomTable';
import { CustomObservations } from '../common/custom-observations/CustomObservations';
import PrescriptionMedicationSelectComponent from './PrescriptionMedicationSelectComponent';
import { CustomDirectionTableCell, CustomFrequnceyTableCell, CustomTableSelectCell } from './CustomTableComponent';
import AlgoSuggestionViewToolTip, { getAlgoSuggestionBasedOnNewMedication } from '../patient-profile/AlgoSuggestionViewToolTip';

import { DURATION, MEDICATION_DOSAGE_UNIT_OPTIONS, MEDICATION_DUREATION_UNITS_OPTIONS } from '../../constants/PrescriptionGenerator';

import './TreatmentAdvice.scss';
import Custom2PointRangeSlider from '../patient-rule/Custom2PointRangeSlider';
import { PatientRuleConditionVitalsOptions } from '../../constants/PatientRules';
import { Box } from '@mui/material';

interface TreatmentAdviceProps {}

const TreatmentAdvice: React.FunctionComponent<TreatmentAdviceProps> = () => {
  const {
    patientReviewPrescriptions,
    patientAlreadyPrescribedMedications,

    patientPrescriptionMedications,
    patientPrescriptionMedicationsErrors,
    handleNewPatientPrescriptionsMedications,
    handleRemovePatientPrescriptionsMedications,
    handleChangePatientPrescriptionMedications,

    prescriptionNotes,
    handleChangeNotes,

    reasonForChange,
    handleChangeReasonForChange,

    patientInfo,

    handleTabSwitchAndSaveObservationToContext,

    fluidIntake,
    handleChangeFluidIntake,
  } = React.useContext(PrescriptionGeneratorContext);

  const [isOpenFrequencyDropdownIndex, setIsOpenFrequencyDropdownIndex] = React.useState<number | null>(null);

  const _renderPrescription = React.useMemo(() => {
    const columnDefs: CustomTableColumnType[] = [
      {
        minWidth: 150,
        width: '20%',
        id: 'medication-name',
        label: 'Medication Name',
        cellFormatter: (data: PrescriptionsGeneratorMedicationsCommonItemType, rowIndex: number) => {
          const error = patientPrescriptionMedicationsErrors?.find((item, index: number) => index === rowIndex);
          return (
            <PrescriptionMedicationSelectComponent
              rowIndex={rowIndex}
              data={data}
              error={error}
              patientPrescriptionMedications={patientPrescriptionMedications}
              handleChangePatientPrescriptionMedications={handleChangePatientPrescriptionMedications}
              patientInfo={patientInfo}
            />
          );
        },
      },

      {
        id: 'Frequency',
        label: 'Frequency',
        minWidth: 150,
        width: '15%',
        cellFormatter: (data: PrescriptionsGeneratorMedicationsCommonItemType, rowIndex: number) => {
          let durationValue: any = MEDICATION_DOSAGE_UNIT_OPTIONS?.find((item) => item.value === data?.dosageUnit);

          if (_.isEmpty(durationValue) && !_.isEmpty(data?.dosageUnit)) {
            durationValue = { __isNew__: true, label: data?.dosageUnit! as any, value: data?.dosageUnit! as any };
          }

          return (
            <Grid key={rowIndex} item xs={12} container alignItems='center' gap={0.5}>
              <Grid item xs={7}>
                <CustomFrequnceyTableCell
                  isOpenFrequencyDropdown={isOpenFrequencyDropdownIndex === rowIndex}
                  handleCloseFrequencyDropdown={() => setIsOpenFrequencyDropdownIndex(null)}
                  handleOpenFrequencyDropdown={() => setIsOpenFrequencyDropdownIndex(rowIndex)}
                  value={data?.frequencyRegime}
                  onChange={(value: string) => handleChangePatientPrescriptionMedications(value, 'frequencyRegime', rowIndex)}
                  data={data}
                  isError={!_.isEmpty(patientPrescriptionMedicationsErrors?.find((item, index: number) => index === rowIndex)?.frequencyRegime)}
                />
              </Grid>
              <Grid item xs={0.2}>
                <Divider orientation='vertical' sx={{ height: '25px', borderColor: '#9BB4CC' }} />
              </Grid>
              <Grid item xs={4}>
                <CustomTableSelectCell
                  key={rowIndex}
                  value={durationValue}
                  menuPortalTarget={document.body}
                  placeholder=''
                  isCreateableSelect
                  options={MEDICATION_DOSAGE_UNIT_OPTIONS}
                  onChange={(value: any) => handleChangePatientPrescriptionMedications(value.value, 'dosageUnit', rowIndex)}
                  isError={!_.isEmpty(patientPrescriptionMedicationsErrors?.find((item, index: number) => index === rowIndex)?.dosageUnit)}
                />
              </Grid>
            </Grid>
          );
        },
      },
      {
        id: 'duration',
        label: 'Duration',
        minWidth: 120,
        width: '5%',
        cellFormatter: (data: PrescriptionsGeneratorMedicationsCommonItemType, rowIndex: number) => {
          let durationValue: any = MEDICATION_DUREATION_UNITS_OPTIONS?.find((item) => data?.durationUnits === item.value);

          if (_.isEmpty(durationValue) && !_.isEmpty(data?.durationUnits)) {
            durationValue = { __isNew__: true, label: data?.durationUnits! as any, value: data?.durationUnits! as any };
          }

          return (
            <Grid key={rowIndex} item xs={12} container alignItems='center'>
              <Grid item xs={4.8}>
                <CustomTableSelectCell
                  key={rowIndex}
                  isCreateableSelect
                  value={DURATION?.find((item) => data?.duration === item.value)}
                  menuPortalTarget={document.body}
                  placeholder=''
                  options={DURATION}
                  onChange={(value: any) => handleChangePatientPrescriptionMedications(value?.value, 'duration', rowIndex)}
                  isError={!_.isEmpty(patientPrescriptionMedicationsErrors?.find((item, index: number) => index === rowIndex)?.duration)}
                />
              </Grid>
              <Grid item xs>
                <Divider orientation='vertical' sx={{ height: '25px', borderColor: '#9BB4CC' }} />
              </Grid>
              <Grid item xs={7}>
                <CustomTableSelectCell
                  key={rowIndex}
                  value={durationValue}
                  menuPortalTarget={document.body}
                  placeholder=''
                  options={MEDICATION_DUREATION_UNITS_OPTIONS}
                  isCreateableSelect={true}
                  onChange={(value: any) => handleChangePatientPrescriptionMedications(value?.value, 'durationUnits', rowIndex)}
                  isError={!_.isEmpty(patientPrescriptionMedicationsErrors?.find((item, index: number) => index === rowIndex)?.durationUnits)}
                />
              </Grid>
            </Grid>
          );
        },
      },
      {
        id: 'directions',
        label: 'Directions',
        minWidth: 200,
        maxWidth: 500,
        width: '20%',
        cellFormatter: (data: PrescriptionsGeneratorMedicationsCommonItemType, rowIndex: number) => (
          <CustomDirectionTableCell
            key={rowIndex}
            value={data?.directionOfUse}
            data={data}
            onChange={(value: any) => handleChangePatientPrescriptionMedications(value, 'directionOfUse', rowIndex)}
            isError={!_.isEmpty(patientPrescriptionMedicationsErrors?.find((item, index: number) => index === rowIndex)?.directionOfUse)}
          />
        ),
      },
      {
        id: 'actions',
        label: '',
        minWidth: 30,
        width: '2%',
        cellAlignment: 'center',
        cellFormatter: (data: PrescriptionsGeneratorMedicationsCommonItemType, rowIndex: number) => (
          <IconButton key={rowIndex} onClick={() => handleRemovePatientPrescriptionsMedications(rowIndex)}>
            <DeleteIcon />
          </IconButton>
        ),
      },
    ];

    return (
      <CustomTable
        count={patientPrescriptionMedications.length}
        columns={columnDefs}
        showAddRow
        addRowBtnLabel='Add Medications'
        handleAddNewRow={() => handleNewPatientPrescriptionsMedications({ durationUnits: 'Days' } as PrescriptionsGeneratorMedicationsCommonItemType)}
        data={patientPrescriptionMedications}
        noDataMessage='No Prescription'
        stickyHeader
        tableSize='small'
        dataRowHover={false}
        addRowBtnHoverEffect
        enableBorderHoverAndFocusEffect
      />
    );
  }, [
    handleChangePatientPrescriptionMedications,
    handleNewPatientPrescriptionsMedications,
    handleRemovePatientPrescriptionsMedications,
    patientInfo,
    patientPrescriptionMedications,
    patientPrescriptionMedicationsErrors,
    isOpenFrequencyDropdownIndex,
  ]);

  const _renderAlgoSuggestion = React.useMemo(() => {
    if (!_.isEmpty(patientReviewPrescriptions) && !_.isEmpty(patientReviewPrescriptions?.algoSuggestion)) {
      const algoSuggestion = getAlgoSuggestionBasedOnNewMedication(patientReviewPrescriptions!, patientAlreadyPrescribedMedications);
      if (algoSuggestion) {
        return algoSuggestion.map((item, index) => {
          if (!_.isEmpty(item)) {
            return (
              <AlgoSuggestionViewToolTip key={index} algoSuggestion={item?.algoSuggestion}>
                <Grid key={index} item xs='auto' container alignItems='center' gap={1}>
                  <Typography component='div' color='#5C6A90' variant='fontReg14'>
                    {item?.reviewSuggestion}
                  </Typography>
                  <InfoOutlinedIcon htmlColor='#9B9A9A' fontSize='small' sx={{ width: '15px', height: '15px' }} />
                </Grid>
              </AlgoSuggestionViewToolTip>
            );
          }
          return null;
        });
      }
    }
    return null;
  }, [patientAlreadyPrescribedMedications, patientReviewPrescriptions]);

  const options = PatientRuleConditionVitalsOptions.find((item) => item.value === 'fluidIntake');

  const sliderProps = {
    tooltipEditable: true,
    minRange: options?.minRange!,
    maxRange: options?.maxRange!,
    defaultRange: options?.defaultRange!,
    value: [fluidIntake.warningHigh, fluidIntake.criticalHigh],
    handleChangeValue: (value: any) => {
      handleChangeFluidIntake(value[0], 'warningHigh');
      handleChangeFluidIntake(value[1], 'criticalHigh');
    },
    step: options?.step,
  };

  return (
    <Grid item xs={12} container gap={7} justifyContent='space-around'>
      <Grid item xs={12} sx={{ '.custom-table': { '.MuiTableCell-root': { fontFamily: 'Lato' } } }}>
        {_renderPrescription}

        <Grid item xs={12} sx={{ mt: 1 }}>
          {_renderAlgoSuggestion && (
            <Grid item gap={1} container alignItems='center'>
              <Typography component='span' color='#4587F0' variant='fontReg14'>
                Suggestions
              </Typography>
              {_renderAlgoSuggestion}
            </Grid>
          )}
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <CustomObservations
          observationActionTitle='Add Notes'
          observationTitle='Notes'
          options={[
            { label: 'Continue same treatment', value: 'Continue same treatment' },
            { label: 'Drink atleast 3 liters of water', value: 'Drink atleast 3 liters of water' },
            { label: 'Do not consume caffeinated drinks', value: 'Do not consume caffeinated drinks' },
          ]}
          handleChangeObservations={handleChangeNotes}
          inputs={[]}
          existingObservations={prescriptionNotes.map((e) => ({ id: Math.random().toString(), mainText: e.notes }))}
          contextStateName='NOTES'
          saveToContext={handleTabSwitchAndSaveObservationToContext}
          showHoverEffectBorderOnInputField
        />
      </Grid>

      <Grid item xs={12}>
        <CustomObservations
          observationActionTitle='Add Notes'
          observationTitle='Reason for Change'
          options={[]}
          handleChangeObservations={handleChangeReasonForChange}
          inputs={[]}
          existingObservations={reasonForChange.map((e) => ({ id: Math.random().toString(), mainText: e.notes }))}
          contextStateName='REASON_FOR_CHANGE'
          saveToContext={handleTabSwitchAndSaveObservationToContext}
          showHoverEffectBorderOnInputField
        />
      </Grid>

      <Grid item xs={12}>
        <Typography variant='fontSemiBold14' color='#33425B'>
          Fluid Intake Recommendation (Litres)
        </Typography>
        <Box sx={{ px: 2 }}>
          <Custom2PointRangeSlider {...sliderProps} />
        </Box>
      </Grid>
    </Grid>
  );
};

export default TreatmentAdvice;
