/** @format */

import * as React from 'react';

import Dialog from '@mui/material/Dialog';
import { SelectedPdfUrlType } from '../../pages/patient-profile/PatientProfileContext';

interface PDFViewerProps {
  handleClose: () => void;
  url: SelectedPdfUrlType | null;

  isOpen: boolean;
}

const PDFViewer: React.FunctionComponent<PDFViewerProps> = (props) => {
  const isPDF = props?.url?.fileType.toLowerCase().includes('application/pdf');
  const isJPEG = props?.url?.fileType.toLowerCase().includes('image/jpeg') || props?.url?.fileType.toLowerCase().endsWith('.image/jpeg');
  return (
    <Dialog maxWidth='md' fullWidth open={props.isOpen} onClose={props.handleClose}>
      {isPDF ? (
        <iframe width='100%' height='800px' src={props?.url?.pdfUrl} title={props?.url?.pdfUrl}></iframe>
      ) : isJPEG ? (
        <img
          src={props?.url?.pdfUrl}
          alt='JPEG Viewer'
          style={{
            maxWidth: '100%',
            maxHeight: '800px',
            objectFit: 'contain',
            overflow: 'hidden',
          }}
        />
      ) : null}
    </Dialog>
  );
};

export default PDFViewer;
